import React, { useEffect, useState } from "react";
import "./Account.scss";
import Dashboard from "../Dashboard/Dashboard";
import Footer from "../Footer/Footer";
import { Link, useNavigate } from "react-router-dom";
import EmailUpdate from "../EmailUpdate/EmailUpdate";
import PasswordReset from "../AccountPasswordReset/PasswordReset";
import PaymentDetails from "../PaymentDetails/PaymentDetails";
import LoginSignService from '../../Services/LoginSignService/LoginSignService';
import DeleteAccount from "../DeleteAccount/DeleteAccount";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ColorRing } from 'react-loader-spinner'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Account() {
  const auth = JSON.parse(localStorage.getItem("user"));
  const [userDetails,setUserDetails] = useState("");
  const [loaderBalance,setLoaderBalance] = useState(false)
  const navigate = useNavigate();

  useEffect(()=>{
  getUserInfo()

  },[])

  //Get User Info
  const getUserInfo = async ()=>{
    setLoaderBalance(true)
    try {
      const token = (auth.data.item.access_token); 
      const response = await LoginSignService.getUserDetails(token)
      setUserDetails(response.data.data)
      setLoaderBalance(false)
      localStorage.setItem('profileimage',response.data.data.profileimagepath)
    } catch (error) {
      if (error.response) {
        console.log(error.data.data.message)
        setLoaderBalance(false)

      } else if (error.request) { 
        console.error('Error request:', error.request);
      } else {
        console.error('Error message:', error.message);
      }
    }
  }

  // Text Copy
  const handleCopy = () => {
    toast('Text copied to clipboard!');

  };

  // logout
  const logout = ()=>{
    localStorage.clear();
    navigate('/')
  }
  
  
  return (
    <Dashboard>
      <div className="main-content ml-250">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-sm-12 col-md-12 col-lg-9">
              <div className="setttings">
                <h2 className="text-center mb-4">Account</h2>
                <div className="setting-crad mb-lg-4">
                  {
                    loaderBalance?(<>
                                <ColorRing
                                      visible={true}
                                      height="80"
                                      width="80"
                                      ariaLabel="color-ring-loading"
                                      wrapperStyle={{}}
                                      wrapperClass="color-ring-wrapper"
                                      colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                                    />
                    </>):(<>
                     <div className="setting-profile d-flex gap-2 gap-lg-3 align-items-center">
                    <img
                      className="img-prof"
                      src={userDetails.profileimagepath??'/images/logo.png'}
                      alt="Profile Images"
                    />
                    <div className="setting-info d-flex justify-content-lg-between align-items-lg-center align-items-start w-100">
                      <div className="left-stt">
                        <h2>{userDetails?userDetails.firstname:''} {userDetails?userDetails.lastname:''}</h2>
                        <p>{userDetails?userDetails.email:''}</p>
                        <span className="d-block">
                            {userDetails?userDetails.referralCode:''}

                            <CopyToClipboard text={userDetails?userDetails.referralCode:''} onCopy={handleCopy}>
                            <i class="fa-regular fa-copy fs-6 ms-1"></i>
                            </CopyToClipboard>


                          
                        </span>
                      </div>
                      <div className="right-stt mt-lg-3">
                        <Link to='/user/editprofile' state={{ userDetails }}>
                        <img
                          src="/images/change-photo.svg"
                          alt="chnage profile"
                        />
                        </Link>
                      </div>
                    </div>
                  </div>
                    </>)
                  }
                 
                </div>
              </div>
              <div className="settings-nav">
                <h4>Account</h4>
                <div className="setting-nav-box">
                  <ul>
                    <li>
                    <li>
                    {["bottom"].map((placement, idx) => (
                          <EmailUpdate
                            key={idx}
                            placement={placement}
                            name={placement}
                            email={userDetails?userDetails.email:''}
                            getUserInfo={getUserInfo}
                          />
                        ))}
                    </li>
                    </li>
                    <li>
                    {["bottom"].map((placement, idx) => (
                          <PasswordReset
                            key={idx}
                            placement={placement}
                            name={placement}
                          />
                        ))}
                    </li>
                    <li>
                    {["bottom"].map((placement, idx) => (
                          <PaymentDetails
                            key={idx}
                            placement={placement}
                            name={placement}
                          />
                        ))}
                    </li>
                    <li>
                      <Link to="/user/kyc" className="d-flex gap-2 justify-content-between">
                        <span className="d-flex gap-2 align-items-center">
                          <i class="fa-solid fa-user-check"></i>
                          <span>Increase Limits</span>
                        </span>
                        <img src="/images/arrow-right.svg" alt="" />
                      </Link>
                    </li>
                    <li>
                      <Link to='/privacy-policy' className="d-flex gap-2 justify-content-between">
                        <span className="d-flex gap-2 align-items-center">
                          <i class="fa-solid fa-shield-halved"></i>
                          <span>Privacy policy</span>
                        </span>
                        <img src="/images/arrow-right.svg" alt="" />
                      </Link>
                    </li>
                    <li>
                      <Link to='/term-of-service' className="d-flex gap-2 justify-content-between">
                        <span className="d-flex gap-2 align-items-center">
                          <i class="fa-solid fa-shield-halved"></i>
                          <span>Terms of Services</span>
                        </span>
                        <img src="/images/arrow-right.svg" alt="" />
                      </Link>
                    </li>
                    <li>
                      <Link to='/help&faq' className="d-flex gap-2 justify-content-between">
                        <span className="d-flex gap-2 align-items-center">
                          <i class="fa-solid fa-comment"></i>
                          <span>Help & FAQ</span>
                        </span>
                        <img src="/images/arrow-right.svg" alt="" />
                      </Link>
                    </li>
                    <li onClick={logout}>
                      <Link className="d-flex gap-2 justify-content-between">
                        <span className="d-flex gap-2 align-items-center">
                          <i class="fa-solid fa-right-from-bracket"></i>
                          <span>Logout</span>
                        </span>
                        <img src="/images/arrow-right.svg" alt="" />
                      </Link>
                    </li>
                  </ul>
                  <div className="mt-5 mt-lg-0">
                  {["bottom"].map((placement, idx) => (
                    <DeleteAccount
                      key={idx}
                      placement={placement}
                      name={placement}
                    />
                  ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
          <Footer />
        </div>
      </div>
    </Dashboard>
  );
}
