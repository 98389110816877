import React, { useEffect, useState } from "react";
import "./Forgot.scss";
import ThemeButton from "../ThemeButton/ThemeButton";
import { Link, useNavigate } from "react-router-dom";
import LoginSignService from "../../Services/LoginSignService/LoginSignService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Forgot() {
  const [email, setEmail] = useState("");
  const [api_key, setApi_key] = useState("");
  const [captchaImg, setCaptchaImg] = useState("");
  const [captchaValue, setCaptchaValue] = useState("");
  const [captchaKeyId, setCaptchaKeyId] = useState("");
  const [loading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState("");
  const navigate = useNavigate();

  const generateCaptch = async () => {
    const apicall = await LoginSignService.GenerateCaptch();
    if (apicall.state === true) {
      //  console.log(apicall.result)
      setCaptchaImg(apicall.result.data.data.captchaimage);
      setCaptchaKeyId(apicall.result.data.data.captchakeyid);
    } else {
      console.log("captcha api failed");
    }
  };

  const authKey = async () => {
    const apicall = await LoginSignService.authKey();
    if (apicall.state === true) {
      //  console.log(apicall.result.data.data.apikey)
      setApi_key(apicall.result.data.data.apikey);
    } else {
      console.log("captcha api failed");
    }
  };

  useEffect(() => {
    generateCaptch();
    authKey();
  }, []);

  const retyrCaptcha = () => {
    generateCaptch();
  };

  const forgotPassword = async (e) => {
    e.preventDefault();
    let data = {
      email: email,
      api_key: api_key,
      captchaKeyId: captchaKeyId,
      captchaValue: captchaValue,
    };
    if (validate()) {
      setLoading(true);
      try {
        const response = await LoginSignService.forgotPass(data);
        setLoading(false);
        navigate("/reset-password");
        localStorage.setItem("useremail", email);
      } catch (error) {
        if (error.response) {
          // The server responded with a status code outside the range of 2xx
          setLoading(false);
          generateCaptch();
          console.error("Error response:", error.response.data.message);
          toast.error(error.response.data.message);
        } else if (error.request) {
          // The request was made but no response was received
          console.error("Error request:", error.request);
          setLoading(false);
        } else {
          // Something happened in setting up the request that triggered an error
          console.error("Error message:", error.message);
          setLoading(false);
        }
      }
    }
  };

  const validate = () => {
    let result = true;
    if (!email) {
      toast.error("Email is required");
      return (result = false);
    }
    if (!captchaValue) {
      toast.error("Captcha is required");
      return (result = false);
    }
    return result;
  };

  return (
    <>
      <div className="forgot-box overflow-x-hidden">
        <div className="container p-0">
          <div className="row align-items-center justify-content-center g-5">
            <div className="col-sm-5 col-md-6 col-lg-4 d-none">
              <div className="login-left">
                <img className="" src="/images/main-bg.svg" alt="" />
              </div>
            </div>
            <div className="col-sm-11 col-md-10 col-lg-8 col-xl-5">
              <div className="login-right">
                <div className="logo-img">
                  <Link to="/">
                    <img src="/images/signup-main-bg.svg" alt="" />
                  </Link>
                </div>
                <div className="d-lg-flex d-grid gap-2 mb-3 mb-lg-0 justify-content-between align-items-center">
                  <div>
                    <h1 className="mb-1">Forgot Password</h1>
                  </div>
                  <div className="back-home">
                    <Link className="" to="/">
                      <i class="fa-solid fa-house" /> Back to Home
                    </Link>
                  </div>
                </div>
                <p>Please enter the email you registered with</p>
                <div className="login-screen mb-3">
                  <form onSubmit={forgotPassword}>
                    <label>Email</label>
                    <div className="input-group">
                      <span className="input-group-text">
                        <img className="" src="/images/mail.svg" alt="" />
                      </span>
                      <input
                        type="text"
                        aria-label="First name"
                        className="form-control"
                        value="ID"
                      />
                      <span className="border-span"></span>
                      <input
                        type="email"
                        aria-label="Last name"
                        className="form-control big-input"
                        placeholder="example@gmail.com"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <label for="Captcha" className="form-label mt-3">
                      Captcha
                    </label>
                    <div className="d-flex align-items-center gap-2 gap-sm-3 justify-content-between">
                      <div className="d-flex align-items-center gap-2">
                        <div className="w-100">
                          <img src={captchaImg} className="captcha_with" />
                        </div>
                        <div>
                          <i
                            class="fa-solid fa-arrows-rotate"
                            onClick={retyrCaptcha}
                          ></i>
                        </div>
                      </div>
                      <div className="w-100 mt-sm-0 mb-2">
                        <input
                          type="text"
                          className="form-control"
                          id="exampleFormControlInput1"
                          placeholder=""
                          value={captchaValue}
                          onChange={(e) => setCaptchaValue(e.target.value)}
                        />
                      </div>
                    </div>
                    <ThemeButton
                      btnName="Proceed"
                      loading={loading}
                    ></ThemeButton>
                  </form>
                  <ToastContainer />
                  <p className="signup-txt mt-3">
                    Already have an account? <Link to="/login">Login</Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
