import React, { useState, useEffect } from "react";
import "./SignUp.scss";
import ThemeButton from "../ThemeButton/ThemeButton";
import LoginSignService from "../../Services/LoginSignService/LoginSignService";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate } from "react-router-dom";

export default function SignUp() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [referredByCode, setReferredByCode] = useState("");
  const [api_key, setApi_key] = useState("");
  const [user_plateform, setUser_plateform] = useState(2);
  const [captchaImg, setCaptchaImg] = useState("");
  const [captchaValue, setCaptchaValue] = useState("");
  const [captchaKeyId, setCaptchaKeyId] = useState("");
  const [loading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState("");
  const navigate = useNavigate();
  let [showPassword, setShowPassword] = useState(false);

  // captcha get
  const generateCaptch = async () => {
    const apicall = await LoginSignService.GenerateCaptch();
    if (apicall.state === true) {
      setCaptchaImg(apicall.result.data.data.captchaimage);
      setCaptchaKeyId(apicall.result.data.data.captchakeyid);
    } else {
      console.log("captcha api failed");
    }
  };

  // auth key api
  const authKey = async () => {
    const apicall = await LoginSignService.authKey();
    if (apicall.state === true) {
      setApi_key(apicall.result.data.data.apikey);
    } else {
      console.log("captcha api failed");
    }
  };

  useEffect(() => {
    generateCaptch();
    authKey();
  }, []);

  // captcha retry
  const retyrCaptcha = () => {
    generateCaptch();
  };

  // signup api
  const signup = async (e) => {
    e.preventDefault();
    let data = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      phone: phone,
      password: password,
      referredByCode: referredByCode,
      api_key: api_key,
      user_plateform: user_plateform,
      captchaValue: captchaValue,
      captchaKeyId: captchaKeyId,
      token: null,
      device_id: null,
    };
    if (validate()) {
      setLoading(true);
      try {
        const response = await LoginSignService.signupService(data);
        localStorage.setItem("user", JSON.stringify(response.data));
        setLoading(false);
        localStorage.setItem(
          "profileimage",
          response.data.data.userDetails.profileimagepath
        );
        localStorage.setItem("joyride", true);
        navigate("/user/dashboard");
      } catch (error) {
        if (error.response) {
          // The server responded with a status code outside the range of 2xx
          setLoading(false);
          toast.error(error.response.data.message);
          generateCaptch();
          setEmailError(
            error.response.data.data ? error.response.data.data.email : ""
          );
        } else if (error.request) {
          // The request was made but no response was received
          console.error("Error request:", error.request);
          setLoading(false);
        } else {
          // Something happened in setting up the request that triggered an error
          console.error("Error message:", error.message);
          setLoading(false);
        }
      }
    }
  };

  // validation signup
  const validate = () => {
    let result = true;
    if (!firstName) {
      toast.error("First name is required");
      return (result = false);
    }
    if (!lastName) {
      toast.error("Last name is required");
      return (result = false);
    }
    if (!email) {
      toast.error("Email is required");
      return (result = false);
    }
    if (!phone) {
      toast.error("Phone is required");
      return (result = false);
    }
    if (!password) {
      toast.error("Password is required");
      return (result = false);
    }
    if (!captchaValue) {
      toast.error("Captcha is required");
      return (result = false);
    }
    return result;
  };

  // Password hide show
  const handlePassword = () => {
    setShowPassword((pre) => !pre);
  };

  // phone
  const handleChangePhone = (e) => {
    const inputValue = e.target.value;
    // Allow only numbers
    const numericValue = inputValue.replace(/[^+0-9]/g, "");
    setPhone(numericValue);
  };
  return (
    <>
      <div className="signup-box overflow-x-hidden">
        <div className="container p-0">
          <div className="inner-box-signup">
            <div className="row align-items-center justify-content-center g-5">
              <div className="col-sm-5 col-md-6 col-lg-4 d-sm-none">
                <div className="signup-left">
                  <img src="/images/signup-main-bg.svg" alt="SignUp" />
                </div>
              </div>
              <div className="col-sm-11 col-md-10 col-lg-8 col-xl-5">
                <div className="signup-right">
                  <div className="logo-img d-none d-sm-block">
                    <Link to="/"><img src="/images/signup-main-bg.svg" alt="" /></Link>
                  </div>
                  <div className="d-lg-flex justify-content-between align-items-center">
                    <div>
                      <h1>
                        Join the future <br /> by filling some info
                      </h1>
                    </div>
                    <div className="back-home">
                      <Link className="" to="/">
                        <i class="fa-solid fa-house" /> Back to Home
                      </Link>
                    </div>
                  </div>

                  <div className="signup-screen mb-3">
                    <form onSubmit={signup}>
                      <div className="d-flex align-items-center justify-content-between gap-2">
                        <div className="w-100">
                          <label className="mt-3">First Name</label>
                          <div className="input-group">
                            <span className="input-group-text">
                              <img
                                className=""
                                src="/images/user-icon.svg"
                                alt=""
                              />
                            </span>
                            <input
                              type="text"
                              aria-label="Last name"
                              className="form-control big-input"
                              placeholder="First Name"
                              value={firstName}
                              onChange={(e) => setFirstName(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="w-100">
                          <label className="mt-3">Last Name</label>
                          <div className="input-group">
                            <span className="input-group-text">
                              <img
                                className=""
                                src="/images/user-icon.svg"
                                alt=""
                              />
                            </span>
                            <input
                              type="text"
                              aria-label="Last name"
                              className="form-control big-input"
                              placeholder="Last Name"
                              value={lastName}
                              onChange={(e) => setLastName(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="d-md-flex gap-2 align-items-center justify-content-between">
                        <div className="w-100">
                          <label className="mt-3">
                            Email <span>{emailError ? emailError : ""}</span>
                          </label>
                          <div className="input-group">
                            <span className="input-group-text">
                              <img className="" src="/images/mail.svg" alt="" />
                            </span>
                            <input
                              type="email"
                              aria-label="Last name"
                              className="form-control big-input"
                              placeholder="example@gmail.com"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="w-100">
                          <label className="mt-3">Phone</label>
                          <div className="input-group">
                            <span className="input-group-text">
                              <img
                                className=""
                                src="/images/phone.svg"
                                alt=""
                              />
                            </span>

                            <input
                              type="phone"
                              aria-label="Last name"
                              className="form-control big-input"
                              placeholder="2347788991100"
                              value={phone}
                              onChange={handleChangePhone}
                              // onChange={(e)=>setPhone(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="position-relative">
                        <label for="inputPassword5" className="form-label mt-3">
                          Password
                        </label>
                        <input
                          type={showPassword ? "text" : "password"}
                          id="inputPassword5"
                          className="form-control"
                          aria-describedby="passwordHelpBlock"
                          placeholder="******"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        <i class="fa-solid fa-eye d-none"></i>
                        <i
                          class={`fa-solid ${
                            showPassword ? "fa-eye" : "fa-eye-slash"
                          }`}
                          onClick={handlePassword}
                        ></i>
                      </div>
                      <label for="Referral" className="form-label mt-3">
                        Referral Code
                      </label>
                      <input
                        type="text"
                        id="Referral"
                        className="form-control"
                        aria-describedby="passwordHelpBlock"
                        placeholder="Referral Code (Optional)"
                        value={referredByCode}
                        onChange={(e) => setReferredByCode(e.target.value)}
                      />
                      <label for="Captcha" className="form-label mt-3">
                        Captcha
                      </label>
                      <div className="d-flex mb-4 align-items-center gap-2 gap-sm-3 justify-content-between">
                        <div className="d-flex align-items-center gap-2">
                          <div className="w-100">
                            <img src={captchaImg} className="captcha_with" />
                          </div>
                          <div>
                            <i
                              class="fa-solid fa-arrows-rotate"
                              onClick={retyrCaptcha}
                            ></i>
                          </div>
                        </div>
                        <div className="w-100 mt-sm-0">
                          <input
                            type="text"
                            className="form-control"
                            id="exampleFormControlInput1"
                            placeholder=""
                            value={captchaValue}
                            onChange={(e) => setCaptchaValue(e.target.value)}
                          />
                        </div>
                      </div>
                      <ThemeButton
                        btnName="Create Account"
                        loading={loading}
                      ></ThemeButton>
                    </form>
                    <ToastContainer />
                    <p className="signup-txt mt-3">
                      Already have an account? <Link to="/login">Login</Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
