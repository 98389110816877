import React, { useEffect, useState } from "react";
import "./FrontPage.scss";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import AOS from "aos";
import "aos/dist/aos.css";
import Form from "react-bootstrap/Form";
import FrontHeader from "./frontheader";
import FrontFooter from "./frontfooter";
import LoginSignService from "../../Services/LoginSignService/LoginSignService";
import {
  Navigation,
  Autoplay,
  Pagination,
  Scrollbar,
  A11y,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";


export default function FrontPage() {
  useEffect(() => {
    AOS.init({
      disable: "phone",
      duration: 700,
      easing: "ease-out-cubic",
    });
  }, []);

  const [list,setList] = useState([]);
  const [rate,setRate] = useState("");
  const [amount,setAmount] = useState("");
  const [totalValue,setTotalValue] = useState("");
  let navigate = useNavigate()
  

  useEffect(() => {
    getCryptoList()
  }, []);
  
  
  const getCryptoList = async ()=>{
    try {
      const response = await LoginSignService.getvcryptolist()
      // console.log(response.data.data)
      setList(response.data.data)
    } catch (error) {
      if (error.response) {
        console.error('Error response:', error.response);
  
      } else if (error.request) { 
        console.error('Error request:', error.request);
  
      } else {
        console.error('Error message:', error.message);
      }
    }
  }
  
  const selecthandle = (e)=>{
    setRate(e.target.value);
  }
  const amounthandle = (e)=>{
    setAmount(e.target.value)
    var amt = (e.target.value)
    setTotalValue(amt * rate); 
  }

  const auth = JSON.parse(localStorage.getItem("user"))
  const token = (auth?.data.item.access_token); 

 
  return (
    <>
      <FrontHeader/>
      <div className="front-main overflow-hidden">
        <div className="container">
          <div className="front-banner" id="about-us">
            <div className="row gy-4">
              <div className="col-lg-6 order-2 order-lg-1">
                <div className="left">
                <h1 className="mb-4" data-aos="flip-left">
                  Instantly Convert Crypto to Naira – Fast, Secure, Reliable!
                </h1>
                <h5 className="mb-5" data-aos="flip-right">
                  Sell Bitcoin, USDT, and More for Instant Naira Payments Direct
                  to Your Bank Account.
                </h5>
                <div className="front-cta d-grid text-center d-lg-flex gap-3 mt-4">
                  <Link className="btnscn" to={token?'/user/dashboard':'login'}  data-aos="fade-right">
                    Sell Crypto Now
                  </Link>
                  <Link className="btnsu" to={token?'/user/dashboard':'sign-up'} data-aos="fade-left">
                    Sign Up for Free
                  </Link>
                </div>
                <div className="app-icons d-flex gap-5 mt-5">
                  <div>
                    <span className="d-block mb-3">Download App</span>
                    <div className="app d-flex gap-3">
                      <Link to="https://play.google.com/store/apps/details?id=com.quickchain.app" target="_blank">
                        <img src="/images/google.png" alt="google" />
                      </Link>
                      <Link to="" target="_blank">
                        <img src="/images/apple-logo.png" alt="Apple-logo" />
                      </Link>
                    </div>
                  </div>
                  <div className="web">
                    <span className="d-block mb-3">Trade on Web</span>
                    <Link to="/sign-up">
                      <img src="/images/world-wide-web.png" alt="web" />
                    </Link>
                  </div>
                </div>
                </div>
              </div>
              <div className="col-lg-6 order-1 order-lg-2">
                <div className="front-bnner">
                  <img data-aos="fade-up" data-aos-duration="1500" src="/images/cryptocurrency-invest.png" alt="Banner" style={{width: "85%"}} />
                </div>
              </div>
            </div>
          </div>
          <div className="trust-signals mt-3">
            <Swiper
              modules={[Autoplay, Navigation, Pagination, Scrollbar, A11y]}
              spaceBetween={20}
              slidesPerView={7}
              breakpoints={{
                0: {
                  slidesPerView: 2,
                },
                575: {
                  slidesPerView: 3,
                },
                767: {
                  slidesPerView: 3,
                },
                991: {
                  slidesPerView: 7,
                },
              }}
              loop={true}
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
              }}
              pagination={{ clickable: true }}
              onSwiper={(swiper) => console.log(swiper)}
              onSlideChange={() => console.log("slide change")}
            >
              <SwiperSlide>
                <div className="testi-card">
                  <div className="crypto-logos">
                    <img src="/images/bitcoin.png" alt="bitcoin" />
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="testi-card">
                  <div className="crypto-logos">
                    <img src="/images/tether.png" alt="tether" />
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="testi-card">
                  <div className="crypto-logos">
                    <img src="/images/ethereum.png" alt="ethereum" />
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="testi-card">
                  <div className="crypto-logos">
                    <img src="/images/doge-logo.png" alt="doge-logo" />
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="testi-card">
                  <div className="crypto-logos">
                    <img src="/images/litecoin.png" alt="litecoin" />
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="testi-card">
                  <div className="crypto-logos">
                    <img src="/images/bitcoin-cash.png" alt="bitcoin-cash" />
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="testi-card">
                  <div className="crypto-logos">
                    <img src="/images/erc20.png" alt="erc20" />
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="testi-card">
                  <div className="crypto-logos">
                    <img src="/images/usdc-logo.png" alt="usdc-logo" />
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="testi-card">
                  <div className="crypto-logos">
                    <img src="/images/xrp-logo.png" alt="xrp-logo" />
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="testi-card">
                  <div className="crypto-logos">
                    <img src="/images/trx-logo.png" alt="trx-logo" />
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="testi-card">
                  <div className="crypto-logos">
                    <img src="/images/bnb.png" alt="bnb" />
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="testi-card">
                  <div className="crypto-logos">
                    <img src="/images/usdt-bep20.png" alt="usdt-bep20" />
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="testi-card">
                  <div className="crypto-logos">
                    <img src="/images/BUSD.png" alt="BUSD" />
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="testi-card">
                  <div className="crypto-logos">
                    <img src="/images/BTCB.png" alt="BTCB" />
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
          <div className="keyfeatures mt-5" data-aos="fade-down">
            <div className="row">
              <div className="col-lg-7">
                <h2>Why Choose QUICKCHAIN?</h2>
                <ul>
                  <li>
                    <img src="/images/check-mark.png" alt="check-mark" />
                    <span>
                      <b>Instant Payments: </b>Get Naira directly in your bank
                      account within minutes.
                    </span>
                  </li>
                  <li>
                    <img src="/images/check-mark.png" alt="check-mark" />
                    <span>
                      <b>Best Exchange Rates: </b>Competitive rates for Bitcoin,
                      USDT, and other cryptocurrencies.
                    </span>
                  </li>
                  <li>
                    <img src="/images/check-mark.png" alt="check-mark" />
                    <span>
                      <b>Secure Transactions: </b>Bank-grade encryption and
                      secure wallet integration.
                    </span>
                  </li>
                  <li>
                    <img src="/images/check-mark.png" alt="check-mark" />
                    <span>
                      <b>User-Friendly Interface: </b>Easy-to-use app for
                      beginners and pros alike.
                    </span>
                  </li>
                  <li>
                    <img src="/images/check-mark.png" alt="check-mark" />
                    <span>
                      <b>24/7 Customer Support: </b>Always here to help with any
                      issues or questions.
                    </span>
                  </li>
                </ul>
              </div>
              <div className="col-lg-5">
                <div className="ch-img">
                  <img src="/images/laptop.png" alt="laptop" />
                </div>
              </div>
            </div>
          </div>
          <div className="sell-crypto-box mt-3 mb-3" id="how-it-work" data-aos="fade-up">
            <div className="row gy-5">
              <div className="col-lg-7 order-lg-2">
                <h2>Simple Steps to Sell Crypto for Naira</h2>
                <ul>
                  <li>
                    <img src="/images/check-mark.png" alt="check-mark" />
                    <span>
                      <b>Sign Up: </b>Create a free account in seconds.
                    </span>
                  </li>
                  <li>
                    <img src="/images/check-mark.png" alt="check-mark" />
                    <span>
                      <b>Sell Crypto: </b>Choose your cryptocurrency, generate a
                      wallet address, send crypto it and its instantly converted
                      to naira!
                    </span>
                  </li>
                  <li>
                    <img src="/images/check-mark.png" alt="check-mark" />
                    <span>
                      <b> Get Paid: </b>Receive instant Naira payments directly
                      to your bank account.
                    </span>
                  </li>
                </ul>
                <div className="mt-5">
                  <Link className="btn-cat" to="/login" data-aos="fade-right">
                    Get Started Now
                  </Link>
                </div>
              </div>
              <div className="col-lg-5 order-lg-1">
                <div className="ch-img">
                  <img src="/images/mocup1.png" alt="laptop" />
                </div>
              </div>
            </div>
          </div>
          <div className="testimonials mt-5" id="testimonials" data-aos="zoom-in">
            <h2 className="text-center">What Our Users Are Saying</h2>
            <div className="testi-slider mt-5 mb-5">
              <Swiper
                modules={[Autoplay, Navigation, Pagination, Scrollbar, A11y]}
                spaceBetween={20}
                slidesPerView={4}
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                  },
                  575: {
                    slidesPerView: 2,
                  },
                  767: {
                    slidesPerView: 3,
                  },
                  991: {
                    slidesPerView: 3,
                  },
                }}
                loop={true}
                autoplay={{
                  delay: 3000,
                  disableOnInteraction: false,
                }}
                pagination={{ clickable: true }}
                onSwiper={(swiper) => console.log(swiper)}
                onSlideChange={() => console.log("slide change")}
              >
                <SwiperSlide>
                  <div className="testi-card">
                    <img src="/images/av4.jpg" alt="" />
                    <h3>John, Lagos</h3>
                    <p>
                      The fastest platform I’ve ever used! My payment arrived in
                      minutes. Highly recommend!
                    </p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="testi-card">
                    <img src="/images/av3.jpg" alt="" />
                    <h3>Amina, Abuja</h3>
                    <p>
                      Amazing rates and excellent support. Selling my Bitcoin
                      has never been easier!
                    </p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="testi-card">
                    <img src="/images/av6.jpg" alt="" />
                    <h3>Frederick Kano</h3>
                    <p>
                      The service was as expected and my payment was sent
                      instantly.
                    </p>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
          <div className="support-coins mt-3">
            <div className="row">
              <div className="col-lg-5">
                 <div className="claulation-crypto h-100"  data-aos="flip-left">
                   <h2 className="text-center">Crypto Calculation</h2>
                   <div className="crad-quick">
                   <Form>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            {/* <Form.Label>Current Password</Form.Label> */}
                            <Form.Select className="w-100" aria-label="Default select example" onChange={selecthandle}>
                              <option>Select crypto</option>
                              {
                                list.length>0?(<>
                                {
                                  list.map((item)=>(
                                    <>
                                     <option value={item.naira_rate}>{item.crypto_name}</option>
                                    </>
                                  ))
                                }
                                </>):(<></>)
                              }
                             
                            </Form.Select>
                          </Form.Group>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Control
                              type="text"
                              placeholder="Enter Amount in $"
                              value={amount}
                              onChange={amounthandle}
                            />
                          </Form.Group>
                          <Form.Group
                            className="mb-4"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Control
                              type="text"
                              placeholder="Rate"
                              value={rate?`${rate}/$`:''}
                            />
                          </Form.Group>
                          <Form.Group
                            className="mb-4"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Control
                              type="text"
                              placeholder="Total Receivable"
                              value={totalValue?`NGN ${totalValue}`:''}
                            />
                          </Form.Group>
                        </Form>
                   </div>
                 </div>
              </div>
              <div className="col-lg-7">
                <div className="support-crypto"  data-aos="flip-right">
                  <h2 className="text-center">Supported Cryptocurrencies</h2>
                  <div className="crad-quick d-grid gap-2">
                      {
                        list.length>0?(<>
                        {
                          list.map((item) => (
                            <>
                                <div className="crypto-coins">
                                  <div className="d-flex justify-content-between align-items-center">
                                  <div className="d-flex gap-2 align-items-center crypto-coinsbox">
                                    <img src={item.crypto_icon} alt="bitoin"/>
                                    <p>{item.crypto_name}</p>
                                  </div>
                                  <div>
                                    <p>₦ {item.naira_rate}/$</p>
                                  </div>
                                  </div>
                                </div>
                            </>
                          ))
                        }
                        </>):(<></>)
                      }
                   </div>
                </div>
              </div>
            </div>
            
          </div>
        </div>
        <FrontFooter />
      </div>
    </>
  );
}
