import React from 'react'
import "./FrontPage.scss";
import { Link } from "react-router-dom";


export default function FrontFooter() {
  const openInNewTab = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };
  const openInNewTab1 = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };
  return (
    <>
    
     <footer id="contact-us">
            <div className="container">
            <div className="row">
              <div className="col-lg-3">
                 <div className="foot-links">
                    <h2>Quick Links</h2>
                    <ul>
                      <li><Link to="/">Home</Link></li>
                      <li><a href="/home#about-us">About Us</a></li>
                      <li><a href="#how-it-work">How It Works</a></li>
                      <li><Link to="/help&faq">FAQs</Link></li>
                      <li><Link to="">Blog</Link></li>
                      <li><Link to="/contact">Contact Us</Link></li>
                      <li><Link to="https://intercom.help/quickchain-80b26a93a5fe/en">Help Centre</Link></li>
                    </ul>
                 </div>
              </div>
              <div className="col-lg-3">
                 <div className="foot-links">
                    <h2>Company</h2>
                    <ul>
                      <li><Link to="/term-of-service">Terms of Service</Link></li>
                      <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                    </ul>
                 </div>
              </div>
              <div className="col-lg-3">
                 <div className="foot-links">
                    <h2>Follow Us On</h2>
                    <ul className="d-flex gap-3">
                      {/* <li><Link to=""><i class="fa-brands fa-facebook-f"/></Link></li> */}
                      <li><Link onClick={() => openInNewTab('https://twitter.com/quickchainapp')}><i class="fa-brands fa-x-twitter"/></Link></li>
                      <li><Link  onClick={() => openInNewTab1('https://www.instagram.com/quickchainapp')}><i class="fa-brands fa-instagram"/></Link></li>
                      {/* <li><Link><i class="fa-brands fa-linkedin-in"/></Link></li> */}
                    </ul>
                 </div>
              </div>
              <div className="col-lg-3">
                 <div className="foot-links">
                    <h2>Contact</h2>
                    <ul className="mb-3">
                      <li><a href="mailto:support@quickchain.com">support@quickchain.com</a></li>
                    </ul>
                    <span className="d-block mb-3 fs-4">Download App</span>
                    <div className="app d-flex gap-3 app-icons">
                      <Link to="https://play.google.com/store/apps/details?id=com.quickchain.app" target='_blank'>
                        <img src="/images/google.png" alt="google"/>
                      </Link>
                      <Link to="" target='_blank'>
                        <img src="/images/apple-logo.png" alt="Apple-logo"/>
                      </Link>
                    </div>
                 </div>
              </div>
            </div>
            <div className="copyquickchain">
               <div className="d-lg-flex d-grid gap-2 justify-content-lg-between align-items-center">
                 <p className="fs-6">Quchange Technologies Limited Copyright 2025 • All rights reserved</p>
                 <div className="d-flex gap-2 gap-lg-5">
                    <div className="d-grid justify-content-center">
                       <img src="/images/sha-256.png" alt=""/>
                       <p>256-bit encryption</p>
                    </div>
                    <div className="d-grid justify-content-center">
                      <img src="/images/secure-credit-card.png" alt=""/>
                       <p>100% Secure Transactions</p>
                    </div>
                 </div>
               </div>
            </div>
            </div>
          </footer>
    </>
  )
}
