



import {useState} from 'react';
import { Line } from 'react-chartjs-2';
import {Chart as ChartJS, Title, Tooltip, LineElement, Legend, CategoryScale, LinearScale, PointElement, Filler} from 'chart.js';
ChartJS.register(
  Title, Tooltip, LineElement, Legend,
  CategoryScale, LinearScale, PointElement, Filler
)

function CoinsChart1(props) {
 
    // const dataSet1 = [];
    // const dataSet2 = [];
    // const dataSet3 = [];
    // const coinsprice = JSON.parse(localStorage.getItem('coinsprice'));
    // const coinsval = JSON.parse(localStorage.getItem('coinsval'));
    // console.log(props.color)
    // if(props.color > 0)
    // {
    //   var up = '#ffffff';
    //   var down = '#07AC27';
    //   var bcolor = '#00a83e';
    // }
    // else{
    //   var up = '#ffffff';
    //   var down = '#ffc7c5';
    //   var bcolor = '#ff3a33';
    // }

    if(props.firstPrice < props.lastPrice)
    {
      var up = '#ffffff';
      var down = '#07AC27';
      var bcolor = '#00a83e';
    }else{
      var up = '#ffffff';
      var down = '#ffc7c5';
      var bcolor = '#ff3a33';
    }
   

    // coinsprice.forEach((item, index) => {
    //     const date = new Date(item[0]);
    //     const formattedDate = date.toLocaleString('en-US', {
    //         timeZone: 'GMT',
    //         year: 'numeric',
    //         month: 'long',
    //         day: 'numeric',
    //         hour: '2-digit',
    //         minute: '2-digit',
    //         second: '2-digit',
    //         hour12: true, // Use 12-hour format
    //     });
    //     dataSet1.push(formattedDate);
    //     dataSet2.push(item[1])
    //     dataSet3.push(coinsval[index][1])
        
    // });
   
    const getGradient = (ctx, chartArea) => {
        if (!chartArea) {
            return; // Prevents errors on initial load
        }
        const gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
        gradient.addColorStop(0, up); // Start color
        gradient.addColorStop(1, down); // End color
        return gradient;
    };
      
  const [data, setData]= useState({
    labels:props.coinprice2,
    datasets:[
      {
        label:"Price",
        label1:"Vol",
        label3:props.coinprice1,
        data:props.coinprice,
        backgroundColor: function(context) {
                    const chart = context.chart;
                    const { ctx, chartArea } = chart;
                    return getGradient(ctx, chartArea); // Use gradient for background
                },
        borderColor:bcolor,
      //   borderColor:function(context) {
      //     const chart = context.chart;
      //     const { ctx, chartArea } = chart;
      //     return getGradient(ctx, chartArea); // Use gradient for background
      // },
      tension: 0.2,
      fill: true,
      pointStyle: 'circle', // You can change this to other styles like 'rect', 'star', etc.
      pointRadius: 1,
      pointHoverRadius: 8,
      pointBorderColor: 'transparent',
      pointBackgroundColor: '#07AC27',
      showLine: true,
      }
      
      
      
    ]
  })
  const options = {
    responsive: true,
    scales: {
        x: {
            ticks: {
                display: false // Hides x-axis tick labels
            },
        },
        y: {
            ticks: {
                display: false // Hides y-axis tick labels
            }
        }
    },
    plugins: {
        legend: {
            display: false // Hides legend if not needed
        } ,tooltip: {
            enabled: true,
            displayColors: false,
            callbacks: {
                label: function(tooltipItem) {
                    const datasetLabel = tooltipItem.dataset.label || '';
                    const datasetLabel1 = tooltipItem.dataset.label1 || '';
                    const value = tooltipItem.raw; // Get the value for this dataset at the hovered index
                    const index = tooltipItem.dataIndex;
                    const otherDatasetValue = tooltipItem.dataset.label3[index]; // Assuming you want the second dataset
                    return [`${datasetLabel}: $${value}`, `${datasetLabel1}: $${otherDatasetValue }`]; // Return an array for multiple lines
                }
            }
          }
    }
  };

  

  return (
    <div className="App" style={{width:'100%', height:'auto'}}>
      <Line data={data} options={options}>Hello</Line>
    </div>
  );
}

export default CoinsChart1;