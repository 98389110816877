import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Dashboard from "../Dashboard/Dashboard";
import Footer from "../Footer/Footer";
import "./CryptoSell.scss";
import ThemeButton from "../ThemeButton/ThemeButton";
import WalletNotice from "../WalletNotice/WalletNotice";
import WalletAddress from "../WalletAddress/WalletAddress";
import CoinsChart from "../CoinsChart/CoinsChart1";
import GetNotify from "../Modals/GetNotify/GetNotify";
import LoginSignService from "../../Services/LoginSignService/LoginSignService";
import { DNA } from 'react-loader-spinner'

export default function CryptoSell() {
  const location = useLocation();
  let { item } = location.state || {};  
  const [key, setKey] = useState("day");
  let from = Math.floor((Date.now() - (24 * 60 * 60 * 1000)) / 1000);
  const [to,setTo] = useState(Math.floor(Date.now() / 1000));
 
  const auth = JSON.parse(localStorage.getItem('user'));
  const [coinprice,setCoinPrice] = useState('');
  const [coinprice1,setCoinPrice1] = useState('');
  const [coinprice2,setCoinPrice2] = useState('');
  const [firstPrice,setFirstPrice] = useState('');
  const [lastPrice,setLastPrice] = useState('');
  const [color,setColor] = useState(item?.changePercent);
  const [load,setLoad] = useState(false);

  useEffect(()=>{
      getCoin();
  },[])

  // get crypto list
  const getCoin = async ()=>{
    try {
      const token = (auth.data.item.access_token); 
      const data = {fromdate:from,todate:to,coin:item.CG_id}
      const response = await LoginSignService.getcoinprice(token,data)
      var datas = response.data.data.prices;
      var datas1 = response.data.data.total_volumes;
      let finalArr = [];
      let finalArr1 = [];
      let finalArr2 = [];
      setFirstPrice(datas[0][1])
      setLastPrice((datas[datas.length-1])[1])
      // localStorage.setItem('coinsprice',(JSON.stringify(response.data.data.prices)));
      // localStorage.setItem('coinsval',(JSON.stringify(response.data.data.total_volumes)));
      datas.forEach((element,index) => {
        finalArr.push(element[1])
        finalArr1.push(datas1[index][1])
        const date = new Date(element[0]);
        const formattedDate = date.toLocaleString('en-US', {
            timeZone: 'GMT',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: true, // Use 12-hour format
        });
        finalArr2.push(formattedDate)

      });
      setCoinPrice(finalArr);
      setCoinPrice1(finalArr1);
      setCoinPrice2(finalArr2);
      setLoad(true)
    } catch (error) {
      if (error.response) {
        console.log(error.response.data.message)
      } else if (error.request) { 
        console.error('Error request:', error.request);
      } else {
        console.error('Error message:', error.message);
      }
    }
  }

  const tabTitles = {
    day: "24",
    day1: "7",
    day2: "30"
};

const handleTabChange = (k) => {
  setKey(k);
  if(tabTitles[k] == '24')
  {
    setLoad(false)
    from = Math.floor((Date.now() - (24 * 60 * 60 * 1000)) / 1000);
    getCoin();
  }else if(tabTitles[k] == '7')
  {
    setLoad(false)
    from = Math.floor((Date.now() - (24 * 60 * 60 * 1000 * 7)) / 1000);
    getCoin(); 
  }else if(tabTitles[k] == '30'){
    setLoad(false)
    from = Math.floor((Date.now() - (24 * 60 * 60 * 1000 *30)) / 1000);
    getCoin();
  }
};
 
  return (
    <Dashboard>
      <div className="main-content crytoquicksell ml-250">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-sm-12 col-md-12 col-lg-9">
              <div className="profile-info-card pb-0">
                <div className="profile-completion d-flex align-items-end justify-content-between">
                  <div className="d-flex align-items-center gap-3">
                    <div>
                      <h1 className="fs-3">Crypto Quick Sell</h1>
                      <span className="fs-6 fw-lighter">
                        Select a crypto to generate your dedicated address
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-sm-12 col-md-12 col-lg-9">
              <div className="cqswa-box">
                <div className="d-lg-flex d-grid gap-4 justify-content-lg-between align-items-start">
                  <div className="">
                    <h2 className="fs-4">
                      {item ? item.crypto_name : ""} (
                      {item ? item.crypto_code : ""})
                    </h2>
                    <div className="d-flex gap-2 align-items-center mt-1">
                      <img
                        src={item ? item.crypto_icon : ""}
                        alt={item ? item.blockchain : ""}
                      />
                      <p className="fs-6">NGN {item ? item.naira_rate : ""}</p>
                    </div>
                  </div>
                  {item?.is_wallet_created === 1 ? (
                    <>
                      <div className="d-flex gap-3 position-relative">
                        <div className="cqs-trans flex-grow-1">
                          <Link to="/user/transcation">
                            <button className="d-flex gap-2 align-items-center justify-content-center w-100">
                              <i class="fa-solid fa-credit-card"></i>
                              <span className="text-white fs-6 d-block">
                                View Transactions
                              </span>
                            </button>
                          </Link>
                        </div>
                        <div>
                        {item?.is_wallet_created === 1 ? (
                        <>
                          {["bottom"].map((placement, idx) => (
                            <WalletAddress
                              key={idx}
                              placement={placement}
                              name={item ? item : ""}
                              button={`Show Wallet Address`}
                              other={'other'}
                            />
                          ))}
                        </>
                      ) : (
                        <>
                          {["bottom"].map((placement, idx) => (
                            <WalletNotice
                              key={idx}
                              placement={placement}
                              name={item ? item : ""}
                              other={'other'}
                            />
                          ))}
                        </>
                      )}
                                       

                          </div>
                      </div>
                    </>
                  ) : (
                    <>
                    {["bottom"].map((placement, idx) => (
                            <WalletNotice
                              key={idx}
                              placement={placement}
                              name={item ? item : ""}
                              other={'other'}
                            />
                          ))}
                    </>
                  )}
                </div>
                <div className="crypto-price-info mt-3 mt-lg-2">
                  <div className="d-flex align-items-center gap-2">
                    <div className="d-flex align-items-center gap-2 flex-grow-1">
                      <div>
                        <p className="text-white mb-0 mt-0 fs-4">
                          Price: ${Number(item?.usdCurrentPrice).toFixed(3)}
                        </p>
                      </div>
                      {
                        item?.changePercent > 0 ? (<>
                        <div><img className="price-icon" src="/images/down-gif.gif" alt=""/><span style={{color: '#69c630'}}>{Number(item?.changePercent).toFixed(2)}%</span></div>
                        </>):(<>
                        <div className="align-items-center gap-1">
                          <img className="price-down-icon" src="/images/arrow-red.gif" alt=""/>
                          <span className="text-danger">{Number(item?.changePercent).toFixed(2)}%</span>
                        </div>
                        </>)
                      }
                    </div>
                    <div className="position-relative">
                      <GetNotify cryptoid={item?.id} name={item?.crypto_name}/>
                    </div>
                  </div>
                </div>
                <div className="graphcryptobox my-3">
                  <div className="d-grid gap-2 map-tab">
                    <Tabs
                      id="controlled-tab-example"
                      activeKey={key}
                      onSelect={handleTabChange}
                      className="mb-3"
                    >
                      <Tab eventKey="day" title="24h">
                        <div  className="text-center">
                          {
                            load?(<>
                            <CoinsChart coinprice={coinprice} coinprice1={coinprice1} coinprice2={coinprice2} color={color} firstPrice={firstPrice} lastPrice={lastPrice} />
                            </>):(<> <DNA
                                              visible={true}
                                              height="80"
                                              width="80"
                                              ariaLabel="dna-loading"
                                              wrapperStyle={{}}
                                              wrapperClass="dna-wrapper"
                                            /></>)
                          }
                        </div>
                      </Tab>
                      <Tab eventKey="day1" title="7d" color={color}>
                        <div  className="text-center">
                          {
                            load?(<>
                            <CoinsChart coinprice={coinprice} coinprice1={coinprice1} coinprice2={coinprice2} color={color} firstPrice={firstPrice} lastPrice={lastPrice}/>
                            </>):(<><DNA
                                              visible={true}
                                              height="80"
                                              width="80"
                                              ariaLabel="dna-loading"
                                              wrapperStyle={{}}
                                              wrapperClass="dna-wrapper"
                                            /></>)
                          }
                        </div>
                      </Tab>
                      <Tab eventKey="day2" title="30d" color={color}>
                        <div  className="text-center">
                          {
                            load?(<>
                            <CoinsChart coinprice={coinprice} coinprice1={coinprice1} coinprice2={coinprice2} color={color} firstPrice={firstPrice} lastPrice={lastPrice} />
                            </>):(<><DNA
                                              visible={true}
                                              height="80"
                                              width="80"
                                              ariaLabel="dna-loading"
                                              wrapperStyle={{}}
                                              wrapperClass="dna-wrapper"
                                            /></>)
                          }
                        </div>
                      </Tab>
                      
                    </Tabs>
                  </div>
                </div>
                <div className="mt-3 border-top pt-3">
                  <h3 className="fs-6">
                    Our Tips <i class="fa-solid fa-angle-down"></i>
                  </h3>
                  <p>{item ? item.rateText : ""}</p>
                </div>
                <div className="mt-3 mt-3 border-bottom pb-3 border-top pt-3">
                  <h3 className="fs-6">
                    About {item ? item.blockchain : ""} (
                    {item ? item.crypto_code : ""}){" "}
                    <i class="fa-solid fa-angle-down"></i>
                  </h3>
                  <p>{item ? item.crypto_description : ""}</p>
                </div>
                <div className="mt-2">
                  {item?.is_wallet_created === 1 ? (
                    <>
                      {["bottom"].map((placement, idx) => (
                        <WalletAddress
                          key={idx}
                          placement={placement}
                          name={item ? item : ""}
                          button={`Show Wallet Address`}
                        />
                      ))}
                    </>
                  ) : (
                    <>
                      {["bottom"].map((placement, idx) => (
                        <WalletNotice
                          key={idx}
                          placement={placement}
                          name={item ? item : ""}
                        />
                      ))}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </Dashboard>
  );
}
