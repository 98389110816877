import React from "react";
import "../EmailUpdate/EmailUpdate.scss";
import { useState } from "react";
import "./EditProfile.scss";
import Dashboard from "../Dashboard/Dashboard";
import Footer from "../Footer/Footer";
import { Link, useLocation } from "react-router-dom";
import Form from "react-bootstrap/Form";
import ThemeButton from "../ThemeButton/ThemeButton";
import LoginSignService from "../../Services/LoginSignService/LoginSignService";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Success from "../Modals/Success/Success";

export default function EditProfile() {

  const location = useLocation();
  const userDetails = location.state || {}
  const defaultImage = userDetails?userDetails.userDetails.profileimagepath:"/images/logo.png";
  const [imageUrl, setImageUrl] = useState(defaultImage); // State to store the image URL
  const [firstname,setFirstName] = useState(userDetails.userDetails.firstname??'');
  const [lastname,setLastName] = useState(userDetails.userDetails.lastname??'');
  const [phone,setPhone] = useState(userDetails.userDetails.phone??'');
  const [password,setPassword] = useState("");
  const [profileimage,setProfileImage] = useState("");
  const [loading,setLoading] = useState(false)
  const auth = JSON.parse(localStorage.getItem("user"));
  const [show, setShow] = useState(false);
  const [success,setSuccess] = useState(false);

  const readURL = (input) => {
    if (input.files && input.files[0]) {
      const reader = new FileReader();
      reader.onload = function (e) {
        setImageUrl(e.target.result); // Set the image URL to state
      };
      reader.readAsDataURL(input.files[0]);
    }
  };

  const handleChange = (e) => {
     setProfileImage(e.target.files[0])
    readURL(e.target); // Call readURL when the input changes
  };

  const updateUserDetail = async (e)=>{
    e.preventDefault();
    if(validate())
    {
      setLoading(true)
      try {
        const token = (auth.data.item.access_token); 
        const details = {firstname:firstname,lastname:lastname,phone:phone,password:password,profileimage:profileimage}
        const response = await LoginSignService.updateUserDetails(details,token)
        setSuccess(true)
        setLoading(false)
        localStorage.setItem('profileimage',response.data.data.profileimagepath)

      } catch (error) {
        if (error.response) {
          toast(error.response.data.message)
          setLoading(false)
          console.log(error.response.data.message)
        } else if (error.request) { 
          setLoading(false)
          console.error('Error request:', error.request);
        } else {
          console.error('Error message:', error.message);
        }
      }
    }
  }

  var validate = ()=>{
    let result = true
    if(!firstname)
    {
      toast("Please enter firstname")
      return result = false;
    }
    if(!password)
    {
      toast("Please enter password")
      return result = false;
    }
    return result;
  }

  // phone 
  const handleChangePhone = (e) => {
    const inputValue = e.target.value;
    // Allow only numbers
    const numericValue = inputValue.replace(/[^.0-9]/g, '');
    setPhone(numericValue);
  };
  return (
    <Dashboard>
      {
        success?(<>
         <Success  setShow={setShow}
          message = {'Profile updated successfully'}
          pageurl={'/user/account'}
          setSuccess={setSuccess}
          img="tick.gif"
          />
        </>):""
      }
      <div className="main-content ml-250">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-sm-12 col-md-12 col-lg-9">
            <Form onSubmit={updateUserDetail}>
              <div className="setttings">
                <h2 className="text-center mb-4">Edit Profile</h2>
                <div className="setting-crad mb-lg-4">
                  <div className="setting-profile d-flex gap-4 gap-lg-4 align-items-center">
                    <div className="img-prof">
                    <div className="avatar-upload">
                          <div className="avatar-edit">
                            <Form.Group controlId="formFile" className="mb-3">
                              <Form.Label for="imageUpload">
                                <i class="fa-solid fa-pencil"></i>
                              </Form.Label>
                              <Form.Control
                                type="file"
                                id="imageUpload"
                                onChange={handleChange}
                                accept="image/*"
                              />
                            </Form.Group>
                          </div>
                          <div class="avatar-preview">
                            <div
                              id="imagePreview"
                              style={{
                                width: "100px",
                                height: "100px",
                                backgroundImage: `url(${imageUrl})`,
                                backgroundSize: "cover",
                                backgroundPosition: "top center",
                                transition:
                                  "background-image 0.65s ease-in-out",
                              }}
                            ></div>
                          </div>
                        </div>
                    </div>
                    {/* <img
                      className="img-prof"
                      src="/images/profile-pic.svg"
                      alt="Profile Images"
                    /> */}
                    <div className="setting-info d-flex justify-content-between align-items-lg-center align-items-start w-100">
                      <div className="left-stt">
                        <h2>{userDetails?userDetails.userDetails.firstname:''} {userDetails?userDetails.userDetails.lastname:''}</h2>
                        <p>{userDetails?userDetails.userDetails.email:''}</p>
                      </div>
                      <div className="right-stt mt-lg-3"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="settings-nav">
                <div className="row justify-content-center">
                  <div className="col-lg-9">
                  <h4 className="mb-3">Edit Profile</h4>
                    <div className="setting-nav-box">
                      
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          {/* <Form.Label>Current Password</Form.Label> */}
                          <Form.Control type="text" 
                          placeholder="First Name"
                          value={firstname}
                          onChange={(e)=>setFirstName(e.target.value)}
                          />
                        </Form.Group>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput2"
                        >
                          {/* <Form.Label>Current Password</Form.Label> */}
                          <Form.Control type="text" placeholder="Last Name"
                          value={lastname}
                          onChange={(e)=>setLastName(e.target.value)}
                          />
                        </Form.Group>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput3"
                        >
                          {/* <Form.Label>Current Password</Form.Label> */}
                          <Form.Control type="text" 
                          placeholder="Phone"
                          value={phone}
                          onChange={handleChangePhone}
                           />
                        </Form.Group>
                        <Form.Group
                          className="mb-1"
                          controlId="exampleForm.ControlInput3"
                        >
                          {/* <Form.Label>Current Password</Form.Label> */}
                          <Form.Control type="password" 
                          placeholder="Password"
                          value={password}
                          onChange={(e)=>setPassword(e.target.value)}
                           />
                        </Form.Group>

                        <ThemeButton btnName="Update" loading={loading} />
                      
                    </div>
                  </div>
                </div>
              </div>
            </Form>
            </div>
          </div>
          <Footer />
        </div>
      </div>
      <ToastContainer/>

    </Dashboard>
  );
}
