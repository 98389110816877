import React, { useState } from "react";
import "./ResetPassword.scss";
import { Link } from "react-router-dom";
import ThemeButton from "../ThemeButton/ThemeButton";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoginSignService from "../../Services/LoginSignService/LoginSignService";
import { useNavigate } from "react-router-dom";

export default function ResetPassword() {
  const [email, setEmail] = useState(localStorage.getItem("useremail"));
  const [otp, setOtp] = useState("");
  const [password, setPassword] = useState("");
  const [password_confirmation, setPassword_confirmation] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [passwordError, setPasswordError] = useState("");
  const [otpError, setOtpError] = useState("");

  const resetPassword = async (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      let data = {
        email: email,
        otp: otp,
        password: password,
        password_confirmation: password_confirmation,
      };
      try {
        const response = await LoginSignService.resetPassword(data);
        setLoading(false);
        navigate("/");
        localStorage.setItem("useremail", email);
      } catch (error) {
        if (error.response) {
          // The server responded with a status code outside the range of 2xx
          setLoading(false);
          console.error(
            "Error response:",
            error.response.data.data?.password[0]
          );
          console.error("Error response:", error.response.data?.message);
          setPasswordError(error.response.data.data?.password[0]);
          setOtpError(error.response.data?.message);
        } else if (error.request) {
          // The request was made but no response was received
          console.error("Error request:", error.request);
          setLoading(false);
        } else {
          // Something happened in setting up the request that triggered an error
          console.error("Error message:", error.message);
          setLoading(false);
        }
      }
    }
  };

  const validate = () => {
    let result = true;
    if (!email) {
      toast.error("Email is required");
      return (result = false);
    }
    if (!otp) {
      toast.error("Otp is required");
      return (result = false);
    }
    if (!password) {
      toast.error("Password is required");
      return (result = false);
    }
    if (!password_confirmation) {
      toast.error("Confirm Password is required");
      return (result = false);
    }
    return result;
  };

  return (
    <>
      <div className="reset-password-box overflow-x-hidden">
        <div className="container p-0">
          <div className="inner-box-signup">
            <div className="row align-items-center justify-content-center g-5">
              <div className="col-sm-5 col-md-6 col-lg-4 d-sm-none">
                <div className="signup-left">
                  <img src="/images/signup-main-bg.svg" alt="SignUp" />
                </div>
              </div>
              <div className="col-sm-11 col-md-10 col-lg-8 col-xl-5">
                <div className="signup-right">
                  <div className="logo-img d-none d-sm-block">
                    <Link to="/">
                      <img src="/images/signup-main-bg.svg" alt="" />
                    </Link>
                  </div>
                  <div className="d-lg-flex d-grid gap-2 mb-3 mb-lg-3 justify-content-between align-items-center">
                    <div>
                      <h1 className="mb-1">Update Password</h1>
                    </div>
                    <div className="back-home">
                      <Link className="" to="/">
                        <i class="fa-solid fa-house" /> Back to Home
                      </Link>
                    </div>
                  </div>
                  <div className="signup-screen mb-3">
                    <form onSubmit={resetPassword}>
                      <div className="d-md-flex gap-2 align-items-center justify-content-between">
                        <div className="w-100">
                          <label>Email</label>
                          <div className="input-group">
                            <span className="input-group-text">
                              <img className="" src="/images/mail.svg" alt="" />
                            </span>
                            <input
                              type="text"
                              aria-label="First name"
                              className="form-control"
                              value="ID"
                            />
                            <span className="border-span"></span>
                            <input
                              type="email"
                              aria-label="Last name"
                              className="form-control big-input"
                              placeholder="ola@gmail.com"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                      <label for="OTP" className="form-label mt-3">
                        OTP
                        <span style={{ color: "red" }}> {otpError}</span>
                      </label>
                      <input
                        type="text"
                        id="OTP"
                        className="form-control"
                        aria-describedby="passwordHelpBlock"
                        placeholder="Enter OTP"
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                      />
                      <div className="d-flex align-items-center gap-3 mb-4">
                        <div className="w-100 position-relative">
                          <label
                            for="inputPassword5"
                            className="form-label mt-3"
                          >
                            Password
                            <span style={{ color: "red" }}>
                              {" "}
                              {passwordError}
                            </span>
                          </label>
                          <input
                            type="password"
                            id="inputPassword5"
                            className="form-control"
                            aria-describedby="passwordHelpBlock"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                          <i class="fa-solid fa-eye d-none"></i>
                          <i class="fa-solid fa-eye-slash"></i>
                        </div>
                        <div className="w-100 position-relative">
                          <label
                            for="inputPassword6"
                            className="form-label mt-3"
                          >
                            Confirm Password
                          </label>
                          <input
                            type="password"
                            id="inputPassword6"
                            className="form-control"
                            aria-describedby="passwordHelpBlock"
                            placeholder="Confirm Password"
                            value={password_confirmation}
                            onChange={(e) =>
                              setPassword_confirmation(e.target.value)
                            }
                          />
                          <i class="fa-solid fa-eye d-none"></i>
                          <i class="fa-solid fa-eye-slash"></i>
                        </div>
                      </div>
                      <ThemeButton
                        btnName="Proceed"
                        loading={loading}
                      ></ThemeButton>
                    </form>
                    <ToastContainer />
                    {/* <p className="signup-txt">
                      Don’t have an account? <a href="#">Sign up</a>
                    </p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
