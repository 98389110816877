import React from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import "./WalletInfo.scss";

export default function WalletInfo({ name,status,amount,date,time,color,bankname,accountname,accountnumber, ...props }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const transactionid = `---Transaction Details---\n Status - ${status}\n Amount - ${amount} \n Bank Name -  ${bankname} \n Account Number - ${accountnumber} \n Account Name - ${accountname}  \n Date - ${date} \n Time - ${time} `;
  const message = `${transactionid}`;
  const encodedMessage = encodeURIComponent(message); // Encode the message for URL
  const whatsappUrl = `https://api.whatsapp.com/send?text=${encodedMessage}`;
  return (
    <>
      <Button variant="primary" onClick={handleShow} className="walletinfo p-0">
        <img src="/images/info-icon.svg" alt="" />
      </Button>
      <Offcanvas
        className="walletinfoffcanvas"
        show={show}
        onHide={handleClose}
        {...props}
      >
        <Offcanvas.Header closeButton className="mt-2 pt-2 pb-0"></Offcanvas.Header>
        <Offcanvas.Body className="pt-0">
          <div className="row justify-content-center">
            <div className="col-sm-12 col-md-12 col-lg-9 col-xl-9 col-xxl-6">
            <div className="walletinfo-box text-white">
            <div className="d-flex info-box-share mt-4 mt-lg-0">
            <h2 className="text-center mt-1">Transcation Details</h2>
            <a href={whatsappUrl} className="Receipt text-white" target="_blank" rel="">
              <img src="/images/Share.gif" alt="share" style={{width: "30px"}}/>
            </a>
            </div>
            <p className={`approved ${color}`}>
            {status} <i className="fa-solid fa-arrow-down" />
            </p>
            <div className="status-card my-3">
              <div className="d-flex justify-content-around py-2 px-4">
                <div className="status-card-one">
                  <div>
                    <span>Status</span>
                    <p>{status}</p>
                  </div>
                  <div>
                    <span>Amount</span>
                    <p>{amount}</p>
                  </div>
                  <div>
                    <span>Bank Name</span>
                    <p>{bankname??'Not added'}</p>
                  </div>
                  <div>
                    <span>Account Number</span>
                    <p>{accountnumber??'Not added'}</p>
                  </div>
                </div>
                <div className="border-end"></div>
                <div className="status-card-one">
                  <div>
                    <span>Date</span>
                    <p>{date}</p>
                  </div>
                  <div>
                    <span>Time</span>
                    <p>{time}</p>
                  </div>
                  <div>
                    <span>Account Name</span>
                    <p>{accountname??'Not added'}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}
