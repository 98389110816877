import React from "react";
import "./PrivacyPolicy.scss";
import FrontHeader from "../FrontPage/frontheader";
import FrontFooter from "../FrontPage/frontfooter";
import { Link } from "react-router-dom";

export default function PrivacyPolicy() {
  return (
    <>
      <FrontHeader />
      <div className="contact-text mb-5">
                  <div className="container">
                    <div className="d-lg-flex justify-content-between align-items-center">
                      <h1 className="text-left fs-1 text-white mb-3 mb-lg-0">Privacy Policy</h1>
                      <div>
                        <nav aria-label="breadcrumb mb-0">
                          <ol class="breadcrumb mb-0 justify-content-lg-end" style={{paddingRight: '15px'}}>
                            <li class="breadcrumb-item">
                              <Link className="text-white" to="/">
                                Home
                              </Link>
                            </li>
                            <li
                              class="breadcrumb-item active text-white"
                              aria-current="page"
                            >
                              Privacy Policy
                            </li>
                          </ol>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
      <div className="static-page mt-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-sm-12 col-md-12 col-lg-10">
              <div className="static-card pt-3">
                {/* <div className='static-image-box'>
                            <img src='/images/static-icon.svg' alt='iocn'/>
                        </div> */}
                <div className="d-grid gap-0 align-items-center justify-content-center">
                  <img
                    src="/images/privacy-policy.png"
                    alt=""
                    style={{ width: "100px", margin: "0 auto 5px" }}
                  />
                  <h2 className="text-center mb-3">Privacy Policy</h2>
                </div>

                <p>
                  <strong>
                  At <b className="text-white">Quchange Technologies Limited</b>, operating as <b className="text-white">QuickChain</b>, your privacy is our utmost priority. We are dedicated to protecting the personal information you provide and ensuring that your interactions with us are safe, secure, and transparent. This Privacy Policy explains how we collect, use, and safeguard your data when you access our services through the QuickChain website or mobile app.
                  </strong>
                </p>
                <h3>1. Information We Collect</h3>
                <p>To deliver our services efficiently and securely, we may collect the following types of data:</p>
                <ul>
                  <li><b className="text-white">Personal Information:</b> Includes your name, email address, phone number, and payment details.</li>
                  <li><b className="text-white">Transaction Data:</b> Details about your cryptocurrency transactions, payment methods, and account activities.</li>
                  <li><b className="text-white">Technical Data:</b> Information about your device, browser type, IP address, and usage statistics (collected through cookies and similar technologies).</li>
                  <li><b className="text-white">Verification Data:</b> Identification documents, photos, and other necessary details for account verification to meet legal requirements.</li>
                </ul>
                <h3>2. How We Use Your Information</h3>
                <p>The information we collect is used for the following purposes:</p>
                <ul>
                  <li><b className="text-white">Service Delivery:</b> Facilitate your cryptocurrency trades and process transactions securely.</li>
                  <li><b className="text-white">Account Management:</b> Verify your identity, manage your account, and provide customer support.</li>
                  <li><b className="text-white">Compliance:</b> Adhere to applicable laws, regulations, and anti-money laundering (AML) requirements.</li>
                  <li><b className="text-white">Enhancements:</b> Improve our platform, services, and overall user experience.</li>
                  <li><b className="text-white">Marketing:</b> Send promotional offers and updates (with the option to opt out).</li>
                </ul>
                <h3>3. How We Protect Your Information</h3>
                <p>We implement advanced security measures to ensure your data is safeguarded, including:</p>
                <ul>
                  <li><b className="text-white">Data Encryption:</b> Encrypting sensitive data during both storage and transmission.</li>
                  <li><b className="text-white">Access Control:</b> Restricting access to authorized personnel only.</li>
                  <li><b className="text-white">Regular Audits:</b> Conducting routine security audits to maintain industry-standard compliance.</li>
                </ul>
                <h3>4. Sharing Your Information</h3>
                <p>We do not sell or rent your personal data to third parties. However, we may share your information in the following cases:</p>
                <ul>
                  <li><b className="text-white">With Service Providers:</b> To assist in transaction processing, analytics, and support services.</li>
                  <li><b className="text-white">For Legal Compliance:</b> To fulfill legal obligations, respond to court orders, or meet government requests.</li>
                  <li><b className="text-white">In Business Transfers:</b> : In the event of a merger, acquisition, or sale of assets, your data may be transferred.</li>
                </ul>
                <h3>5. Cookies and Tracking Technologies</h3>
                <p>We use cookies and similar technologies to enhance your experience on our platform. These technologies allow us to:</p>
                <ul className="mb-0">
                  <li>Monitor platform usage and improve functionality.</li>
                  <li>Provide personalized content and offers.</li>
                  <li>Enhance account security.</li>
                </ul>
                <p>You can manage your cookie preferences in your browser settings.</p>
                <h3>6. Your Rights</h3>
                <p>As a user, you have the following rights concerning your personal information:</p>
                <ul className="mb-0">
                  <li><b className="text-white">Access and Update:</b> Request access to or updates of your data.</li>
                  <li><b className="text-white">Deletion:</b> Request the deletion of your account and personal information, subject to legal obligations.</li>
                  <li><b className="text-white">Opt-Out:</b> Decline promotional communications.</li>
                </ul>
                <p>To exercise these rights, contact us at  <a href='mailto:support@quickchain.com'>support@quickchain.com</a> </p>
                <h3>7. Data Retention</h3>
                <p>Your data will only be retained as long as necessary to fulfill the purposes outlined in this Privacy Policy or to meet legal and regulatory obligations.</p>
                <h3>8. Third-Party Links</h3>
                <p>Our platform may include links to third-party websites. We are not responsible for their privacy practices and recommend reviewing their respective privacy policies.</p>
                <h3>9. Updates to This Privacy Policy</h3>
                <p>This Privacy Policy may be updated periodically to reflect changes in our services or legal requirements. Updates will be posted on this page with the revised effective date.</p>
                <h3>10. Contact Us</h3>
                <p className="mb-0">For questions or concerns about this Privacy Policy, please contact us at:</p>
                <p><b className="text-white">Email:</b> <a href='mailto:support@quickchain.com'>support@quickchain.com</a></p>
                <p className="fs-5">Thank you for trusting <b className="text-white">Quchange Technologies Limited.</b> Your privacy is our commitment!</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FrontFooter />
    </>
  );
}
