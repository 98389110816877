import React from 'react'
import "./FrontPage.scss";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

export default function FrontHeader() {
  const auth = JSON.parse(localStorage.getItem("user"))
  const token = (auth?.data.item.access_token); 
  const profileimagepath = (auth?.data.userDetails.profileimagepath); 
  console.log(profileimagepath)
  return (
    <>
      <header className="front-nav">
        <Navbar expand="lg" className="bg-body-tertiary w-100">
          <Container>
            <Navbar.Brand>
              <Link className="navbar-brand" to="/">
                <img src="/images/logo.png" alt="logo" />
                <span className="text-white">QUICKCHAIN</span>
              </Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ms-lg-auto">
                <ul className="navbar-nav ms-lg-auto mb-2 mb-lg-0 align-items-lg-center">
                  <li className="nav-item">
                    <Link
                      className="nav-link active"
                      aria-current="page"
                      to="/"
                    >
                      Home
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/help&faq">
                      FAQs
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/privacy-policy">
                      Privacy Policy
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/term-of-service">
                      Term of Service
                    </Link>
                  </li>
                  <li className="nav-item d-none">
                    <a className="nav-link" href="#testimonials">
                      Testimonials
                    </a>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/contact">
                      Contact Us
                    </Link>
                  </li>
                  {
                    !token?
                    (<>
                    <li className="nav-item mb-3 mb-lg-0">
                    <Link className="nav-link login" to="/Login">
                      Login
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link signup" to="/sign-up">
                      Sign Up
                    </Link>
                  </li>
                    </>): <li className="nav-item">
                    <Link className="nav-link" to="/user/dashboard">
                     <img className='user-dash' style={{border: '1.5px solid #fff', borderRadius: '100vmax', width: '35px'}} src={profileimagepath?profileimagepath:'/images/logo.png'} alt='user' /> Dashboard
                    </Link>
                  </li>
                  }
                  
                 
                </ul>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>
    </>
  )
}

