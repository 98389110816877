import React from "react";
import "./BlankCard.scss";

export default function BlankCard() {
  return (
    <div className="row justify-content-center">
      <div className="col-sm-12 col-md-12 col-lg-12">
        <div className="blank-box mt-3">
          <div className="blank-icon">
            <img src="/images/empty.png" alt="" />
          </div>
          <h2>No Transactions Found</h2>
          <p>You don't have any transctions yet.</p>
        </div>
      </div>
    </div>
  );
}
