import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import LoginSignService from "../../../Services/LoginSignService/LoginSignService";
import "./Adv.scss";
import {
  Navigation,
  Autoplay,
  Pagination,
  Scrollbar,
  A11y,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

export default function Adv({bannerList}) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const auth = JSON.parse(localStorage.getItem('user'));
  // const [list,setList] = useState([]);
  useEffect(() => {
    // Check if the modal has been shown before
    const modalShown = localStorage.getItem("modalShown");
    if (!modalShown) {
      handleShow(); // Show the modal
      localStorage.setItem("modalShown", "true"); // Set the flag
    }
    // banner();
  }, []);

  // const banner =async ()=>{
  //   try {
  //     const token = (auth.data.item.access_token); 
  //     const response = await LoginSignService.getBanner(token)
  //     setList(response.data.data)
  //   } catch (error) {
  //     if (error.response) {
  //       console.log(error.data.data.message)
  //     } else if (error.request) { 
  //       console.error('Error request:', error.request);
  //     } else {
  //       console.error('Error message:', error.message);
  //     }
  //   }
  // }

  return (
    <>
      {/* <Button className="w-100" onClick={handleShow}>
        Adv
      </Button> */}

      <Modal
        show={show}
        onHide={handleClose}
        className="adv-popup"
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div>
          <Modal.Header closeButton className="border-0 p-0 position-relative">
            <Modal.Title className="text-center"></Modal.Title>
          </Modal.Header>
          <div className="d-flex d-lg-block justify-content-center h-100">
            <Modal.Body className="p-0 w-100">
              <div className="success-info">
                <Swiper
                  modules={[Autoplay, Navigation, Pagination, Scrollbar, A11y]}
                  spaceBetween={20}
                  slidesPerView={7}
                  breakpoints={{
                    0: {
                      slidesPerView: 1,
                    },
                    575: {
                      slidesPerView: 1,
                    },
                    767: {
                      slidesPerView: 1,
                    },
                    991: {
                      slidesPerView: 1,
                    },
                  }}
                  loop={true}
                  autoplay={{
                    delay: 3500,
                    disableOnInteraction: false,
                  }}
                  pagination={{ clickable: true }}
                  onSwiper={(swiper) => console.log(swiper)}
                  onSlideChange={() => console.log("slide change")}
                >
                  {
                    bannerList.length>0?(<>
                    {
                      bannerList.map((item)=>(
                        <>
                        <SwiperSlide>
                          <div className="testi-card">
                            <div className="crypto-logos">
                              <img src={item.bannerImage_path} alt="banner" />
                            </div>
                          </div>
                        </SwiperSlide>
                        </>
                      ))
                    }
                    </>):(<></>)
                  }
                  
                  
                </Swiper>
              </div>
            </Modal.Body>
          </div>
        </div>
      </Modal>
    </>
  );
}
