import axios from "axios";
 let url = "https://qkchn.testersgroup.com";
//  let url = "http://127.0.0.1:8000";
var LoginSignService = {
  GenerateCaptch: function () {
    let url1 = `${url}/api/auth/generate-captcha`;
    return axios.get(url1).then((result) => {
      if (result.status === 200) {
        return { result: result, state: true };
      } else {
        return { result: result, state: false };
      }
    });
  },
  authKey: function () {
    let url1 = `${url}/api/auth/auth-key?password=Quickchain.flutter.app@secure`;
    return axios.get(url1).then((result) => {
      if (result.status === 200) {
        return { result: result, state: true };
      } else {
        return { result: result, state: false };
      }
    });
  },
  loginService: function (data) {
    return axios.post(`${url}/api/auth/login`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  signupService: function (data) {
    return axios.post(`${url}/api/auth/register`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  forgotPass: function (data) {
    return axios.post(`${url}/api/auth/forgot-password`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  resetPassword: function (data) {
    return axios.post(`${url}/api/auth/verify-otp`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  getBalance: function (id, token) {
    return axios.get(`${url}/api/auth/get-balance?walletTypeId=${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
  },
  withDrawalHistory: function (id, token) {
    return axios.get(`${url}/api/auth/withdraw-history?walletTypeId=${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
  },
  getWalletCurrency: function (token) {
    return axios.get(`${url}/api/auth/get-wallet-currencies`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
  },
  withdrawBalance: function (data, token) {
    return axios.post(`${url}/api/auth/withdraw-balance`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
  },
  updateEmail: function (data, token) {
    return axios.post(`${url}/api/auth/update-email`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
  },
  getUserDetails: function (token) {
    return axios.get(`${url}/api/auth/get-user-details`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
  },
  updatePassword: function (data, token) {
    return axios.post(`${url}/api/auth/update-password`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
  },
  getBankList: function (token) {
    return axios.get(`${url}/api/auth/get-bank-list`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
  },
  GetRedbillerAccountName: function (bankcode, accountno, token) {
    return axios.get(
      `${url}/api/auth/verify-red-biller-bank?bank_code=${bankcode}&account_no=${accountno}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      }
    );
  },
  updateBankDetails: function (data, token) {
    return axios.post(`${url}/api/auth/update-bank-details`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
  },
  getPaymentDetail: function (token) {
    return axios.get(`${url}/api/auth/get-payment-details`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
  },
  getNotification: function (token) {
    return axios.get(`${url}/api/auth/get-notification`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
  },
  getCryptoTransaction: function (data, token) {
    return axios.post(`${url}/api/auth/get-vc-transaction`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
  },
  getCryptoList: function (token) {
    return axios.get(`${url}/api/auth/get-vaultody-list`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
  },
  generateCryptoAddress: function (id, token) {
    return axios.post(
      `${url}/api/auth/generate-vc-address?crypto_id=${id}`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      }
    );
  },
  updateUserDetails: function (data, token) {
    return axios.post(`${url}/api/auth/update-user-detail`, data, {
      headers: {
        "Content-Type": "application/json",
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + token,
      },
    });
  },
  kycSubmit: function (data, token) {
    return axios.post(`${url}/api/auth/kyc-web`, data, {
      headers: {
        "Content-Type": "application/json",
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + token,
      },
    });
  },
  kycUpload: function (data, token) {
    return axios.post(`${url}/api/auth/kyc`, data, {
      headers: {
        "Content-Type": "application/json",
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + token,
      },
    });
  },
  getKyc: function (token) {
    return axios.get(`${url}/api/auth/kyc-list`, {
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'multipart/form-data',
        Authorization: "Bearer " + token,
      },
    });
  },
  deleteAccount: function (token) {
    return axios.get(`${url}/api/auth/delete-account`, {
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'multipart/form-data',
        Authorization: "Bearer " + token,
      },
    });
  },
  getMenu: function (token) {
    return axios.get(`${url}/api/auth/menu-visibility?platform=web`, {
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'multipart/form-data',
        Authorization: "Bearer " + token,
      },
    });
  },
  getcoinprice: function (auth,data) {
    return axios.post(
      `${url}/api/auth/get-coin-price`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'multipart/form-data',
          Authorization: "Bearer " + auth,

        },
      }
    );
  },
  getvcryptolist: function () {
    return axios.get(`${url}/api/auth/get-vaultody-list-front`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  saveNotify: function (data,token) {
    return axios.post(`${url}/api/auth/set-notify`, data, {
      headers: {
        "Content-Type": "application/json",
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + token,
      },
    });
  },
  getBanner: function (token) {
    return axios.get(`${url}/api/auth/get-banner?platform=web`, {
      headers: {
        "Content-Type": "application/json",
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + token,
      },
    });
  },
  contactus: function (data) {
    return axios.post(`${url}/api/auth/contact-us`,data, {
      headers: {
        "Content-Type": "application/json",
        "Content-Type": "multipart/form-data",
      },
    });
  },
};
export default LoginSignService;
