import React,{useEffect, useState} from "react";
import Dashboard from "../Dashboard/Dashboard";
import Footer from "../Footer/Footer";
import Form from "react-bootstrap/Form";
import ThemeButton from "../ThemeButton/ThemeButton";
import "./KYC.scss";
import KycRequest from "../Modals/KycRequest/KycRequest";
import Camera from "../Modals/Camera/Camera";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoginSignService from "../../Services/LoginSignService/LoginSignService";
import { DNA } from 'react-loader-spinner'
import Kycweb from "../Modals/KycWeb/kycweb";
import {useNavigate } from "react-router-dom";

export default function KYC() {

  const auth = JSON.parse(localStorage.getItem('user'))
  const [bvn,setBvn] = useState("");
  const [image,setImage] = useState(localStorage.getItem('kycimage'));
  const [uploadimage,setUploadImage] = useState("");
  const [loading,setLoading] = useState(false)
  const [list,setList] = useState("");
  const [loader,setLoader] = useState(false)
  const [show,setShow] = useState(false)
  const [show1,setShow1] = useState(false)
  const [imageSubmit,setimageSubmit] = useState(false);

  const [userid,setUserId] = useState(auth.data.userDetails.id);
  
  // Store kyc
  const handleKyc = async (e) =>{
    e.preventDefault();
    if(validate())
    {
      try {
        setLoading(true)
        const token = (auth.data.item.access_token); 
        // const token = ''; 
        if(uploadimage != null && uploadimage != '')
        {
          const data = {image:image,bvn:bvn}
          console.log('kyc',data)
          const response = await LoginSignService.kycUpload(data,token)
        }
        else{
          const data = {image:image,bvn:bvn}
          console.log('upload',data)
          const response = await LoginSignService.kycSubmit(data,token)

        }
        // toast(response.data.message)
        setLoading(false)
        getKyclist()
        localStorage.removeItem('kycimage')
        setBvn("");
        setImage("")
        setShow(false)
        setimageSubmit(true)
      } catch (error) {
        if (error.response) {
          toast(error.response.data.message)
          setLoading(false)
          console.log(error.response.data.message)
        } else if (error.request) { 
          setLoading(false)
          console.error('Error request:', error.request);
        } else {
          console.error('Error message:', error.message);
        }
      }
    }
  }

  // validation 
  const validate = ()=>{
    let result = true;
    if(!image)
    {
      toast("PASSPORT/NIN/DRIVER'S LICENSE/NIMC/VOTER'S ID is required");
      return result = false;
    }
    // if(!bvn)
    // {
    //   toast("Bvn is required");
    //   return result = false;
    // }
    return result;
  }

  // get kyc
  const getKyclist = async () =>{
      try {
        setLoader(true)
        const token = (auth.data.item.access_token); 
        const response = await LoginSignService.getKyc(token)
        setList(response.data.data)
        setLoader(false)
        if(response.data.success === false)
        {
          setShow(true)
        }
      } catch (error) {
        if (error.response) {
          // toast(error.response.data.message)
          console.log(error.response.data.message)
          setLoader(false)
          if(error.response.data.success === false)
          {
            setShow(true)
          }
        } else if (error.request) { 
          console.error('Error request:', error.request);
        } else {
          console.error('Error message:', error.message);
        }
      }
  }

  useEffect(()=>{
    getKyclist()
  },[])

  const retrykyc = ()=>{
    setShow(true);
    setShow1(true)
  }
  const navigate = useNavigate();
  const continueDashboard = ()=>{
    navigate('/user/dashboard')
  }
  return (
    <>
      <Dashboard>
        {
          imageSubmit?(<><KycRequest imageSubmit = {imageSubmit}/></>):""
        }
        <div className="main-content kyc ml-250">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-sm-12 col-md-12 col-lg-9">
                <div className="profile-info-card pb-0">
                  <div className="profile-completion d-flex align-items-end justify-content-between">
                    <div className="d-flex align-items-center gap-3">
                      <div>
                        <h1 className="fs-3">Increase Limits</h1>
                        <span className="fs-6 fw-lighter">
                          Submit your KYC to increase withdrawal limits
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-sm-12 col-md-12 col-lg-9">
                <div className="kyc-box pb-4 mt-0 mt-lg-4">
                  <div className="row justify-content-center">
                    <div className="col-sm-12 col-md-12 col-lg-9">
                     {
                      loader?(<>
                      <div className="row">
                          <div className="col-md-12 text-center">
                          <DNA
                              className="text-center"
                              visible={true}
                              height="80"
                              width="80"
                              ariaLabel="dna-loading"
                              wrapperStyle={{}}
                              wrapperClass="dna-wrapper"
                            />
                          </div>
                        </div> 
                      </>):(<>
                         
                             
                        {list?.status === 1 && 
                              <div className="kyc-text-box text-center">
                                <img src="/images/pending.png" alt="Pending" />
                                <h2 className="text-center">KYC is Pending</h2>
                                <p>
                                 Your KYC verification is in progress. You may close this screen, as the process typically takes a few minutes to an hour.
                                </p>
                              </div>
                            }
                      
                            {show1?(<></>):(<>
                              {list?.status === 3 && 
                              <div className="kyc-text-box failed text-center">
                                <img src="/images/remove.png" alt="failed" />
                                <h2 className="text-center">KYC Verification Failed</h2>
                                <p className="mb-2">{list.reason}</p>
                                <button onClick={retrykyc}>Retry</button>
                              </div>
                             
                            }
                            </>)}
                           

                            {list?.status === 2 && 
                               <div className="kyc-text-box kyc-success text-center">
                               <img src="/images/customer.png" alt="kyc" />
                               <h2 className="text-center">
                                 KYC Verification Successful
                               </h2>
                               <p className="mb-2">
                                 Identity Verified. Start Enjoying Unlimited Transactions.
                               </p>
                               <button className='theme-btn' onClick={continueDashboard}>Continue</button>
                               
                              </div>
                            }
                            {
                              show?(<>
                               <div className="kyc-form-box">
                                    <p className="text-center">
                                      <i class="fa-solid fa-triangle-exclamation" /> Upload
                                      the required documents to become KYC VERIFIED
                                    </p>
                                    <Form onSubmit={handleKyc}>
                                      <Camera setUploadImage={setUploadImage} setImage = {setImage}/>
                                      <Form.Group
                                        className="mb-1"
                                        controlId="exampleForm.ControlInput1"
                                      >
                                        <Form.Control
                                          type="text"
                                          placeholder="Enter BVN (Optional)"
                                          value={bvn}
                                          onChange={(e)=>setBvn(e.target.value)}
                                        />
                                      </Form.Group>
                                      <ThemeButton btnName="Submit" loading={loading} />
                                    </Form>
                                </div>

                                  {/* <Kycweb userid={userid}/> */}
                              </>):(<></>)
                            }

                      </>)
                     }
                     
                      

                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
            <ToastContainer />
          </div>
        </div>
      </Dashboard>
    </>
  );
}
