import React from "react";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useEffect, useRef } from "react";
import "./GetNotify.scss";
import Form from "react-bootstrap/Form";
import ThemeButton from "../../ThemeButton/ThemeButton";
import LoginSignService from "../../../Services/LoginSignService/LoginSignService";
import { ToastContainer, toast } from 'react-toastify';
import Success from "../Success/Success";
import 'react-toastify/dist/ReactToastify.css';

export default function GetNotify(props) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
    setAmount("")
  } 
  const [amount,setAmount] = useState("");
  const [cryptoId,setCryptoId] = useState(props.cryptoid);
  const auth = JSON.parse(localStorage.getItem('user'));
  const [loading,setLoading] = useState(false);
  const [success,setSuccess] = useState(false);

  useEffect(() => {
      // Import Bootstrap's Tooltip
      const bootstrap = require("bootstrap"); // Ensure Bootstrap is defined
      const tooltipTriggerList = [].slice.call(
        document.querySelectorAll('[data-bs-toggle="tooltip"]')
      );
      tooltipTriggerList.map(
        (tooltipTriggerEl) => new bootstrap.Tooltip(tooltipTriggerEl)
      );
    }, []);
  
    const buttonRef = useRef(null); // Reference to the button
  
    useEffect(() => {
      const bootstrap = require("bootstrap"); // Import Bootstrap
      const tooltip = new bootstrap.Tooltip(buttonRef.current, {
        trigger: "manual", // Manual trigger
      });
  
      // Show the tooltip on screen load
      tooltip.show();
  
      // Hide the tooltip after 5 seconds
      const timer = setTimeout(() => {
        tooltip.hide();
      }, 5000);
  
      // Cleanup timer and tooltip on unmount
      return () => {
        clearTimeout(timer);
        tooltip.dispose({});
      };
    }, []);


  const notifySave = async (e)=>{
    e.preventDefault();
    if(validate())
    {
      const arg= {amount:amount,cryptoid:cryptoId};
      try {
        setLoading(true);
        const token = (auth.data.item.access_token); 
        const response = await LoginSignService.saveNotify(arg,token)
        setLoading(false);
        setSuccess(true);
        // setAmount("")
      } catch (error) {
        if (error.response) {
          setLoading(false);
          
          console.error('Error response:', error.response.data.message);
          toast.error(error.response.data.message)
          // setError(error.response.data.message)
         
        } else if (error.request) { 
          setLoading(false);
          console.error('Error request:', error.request);
        } else {
          setLoading(false);
          console.error('Error message:', error.message);
        }
      }
    }
  }

  const validate = ()=>{
    let result = true;
    if(!amount)
    {
      toast.error("Amount is required");
      return result = false;
    }
    return result;
  }

  const handleAmount=(e)=>{
    const inputValue = e.target.value;
    const numericValue = inputValue.replace(/[^.0-9]/g, '');
    setAmount(numericValue);
  }

  return (
    <>
      {/* <Button className="btn btn-theme w-100" onClick={handleShow}>
        REQUEST WITHDRAW
      </Button> */}
      {
        success?(<>
         <Success setSuccessShow={""} setShow={setShow} setSuccess={setSuccess}
        img="tick.gif"
        message1={`We heard it right! You'll be notified when the ${props.name} price will reach $ ${amount}.`}/>
          </>):""
        }
      <button onClick={handleShow}
        ref={buttonRef}
        type="button"
        className="btn btn-secondary p-0 border-0"
        data-bs-toggle="tooltip"
        data-bs-html="true"
        data-bs-title="Get Notified when Price gets $"
        style={{
          border: "1px solid transparent",
          background: "transparent",
          borderRadius: "6px",
          cursor: "pointer",
          animation: "wiggle 2s linear infinite",
        }}
      >
        <img
          className="notify-bell"
          src="/images/bell-alert.png"
          alt="Bell Alert"
        />
      </button>

      <Modal
        show={show}
        onHide={handleClose}
        className="rw-popup"
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton className="border-0 pb-0">
          <Modal.Title className="text-center"></Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-2">
          <div className="rw-info">
            <h2 className="text-center mb-4">
              Get Notified When Price Gets $
            </h2>
            <div className="rw-form mb-4">
              <Form onSubmit={notifySave}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Amount</Form.Label>
                  <Form.Control type="Text" placeholder="Enter Your Amount" value={amount} onChange={handleAmount}/>
                </Form.Group>
                <ThemeButton btnName="Notify" loading={loading}/>
              </Form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <ToastContainer/>
    </>
  );
}
