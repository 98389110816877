import React from 'react';
import './TermService.scss';
import FrontHeader from '../FrontPage/frontheader';
import FrontFooter from '../FrontPage/frontfooter';
import { Link } from "react-router-dom";


export default function TermService() {
  return (
    <>
    <FrontHeader/>
    <div className="contact-text mb-5">
            <div className="container">
              <div className="d-lg-flex justify-content-between align-items-center">
                <h1 className="text-left fs-1 text-white mb-3 mb-lg-0">Terms of Service</h1>
                <div>
                  <nav aria-label="breadcrumb mb-0">
                    <ol class="breadcrumb mb-0 justify-content-lg-end" style={{paddingRight: '15px'}}>
                      <li class="breadcrumb-item">
                        <Link className="text-white" to="/">
                          Home
                        </Link>
                      </li>
                      <li
                        class="breadcrumb-item active text-white"
                        aria-current="page"
                      >
                        Terms of Service
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
     <div className="static-page mt-5">
          <div className='container'>
             <div className='row justify-content-center'>
                <div className='col-sm-12 col-md-12 col-lg-10'>
                    <div className='static-card'>
                        {/* <div className='static-image-box'>
                            <img src='/images/static-icon.svg' alt='iocn'/>
                        </div> */}
                        <div className="d-grid gap-0 align-items-center justify-content-center">
                  <img
                    src="/images/terms.png"
                    alt=""
                    style={{ width: "100px", margin: "0 auto 5px" }}
                  />
                  <h2 className='text-center mb-3'>Terms of Service</h2>
                </div>
                        
                        <p>Welcome to QuickChain, operated by Quchange Technologies Limited. By registering on our platform or using our services through the QuickChain website or mobile app, you agree to abide by these Terms of Service. Please read them carefully before proceeding.</p>
                        <h3>1. Acceptance of Terms</h3>
                        <p>By accessing QuickChain, you:</p>
                        <ul>
                          <li>Acknowledge that information on our platform does not constitute legal, financial, or professional advice.</li>
                          <li>Accept all risks associated with using QuickChain and release QuickChain from liability for any losses incurred.</li>
                          <li>Consent to the suspension of your account pending investigation if any suspicious activities are detected.</li>
                          <li>Agree to provide accurate and truthful information during registration and account verification.</li>
                        </ul>
                        <h3>2. User Responsibilities</h3>
                        <ul>
                          <li><b className='text-white'>Account Security:</b> You are responsible for maintaining the confidentiality of your login credentials (e.g., passwords, PINs) and all activities under your account.</li>
                          <li><b className='text-white'>Prohibited Use:</b> Sharing, selling, or lending your account credentials to others is strictly prohibited.</li>
                          <li><b className='text-white'>Accuracy of Information:</b> Ensure all transaction details, including wallet addresses and amounts, are correct before proceeding. QuickChain will not be liable for errors due to user negligence.</li>
                        </ul>
                        <h3>3. Transactions</h3>
                        <ul>
                          <li>The blockchain network (e.g., Bitcoin Network) governs transaction confirmations. QuickChain is not liable for network delays, protocol errors, or other issues beyond our control.</li>
                          <li>All trade orders placed on QuickChain are binding and cannot be canceled or reversed.</li>
                          <li>Withdrawal methods other than Naira or Cedis bank accounts are processed using the parallel market rate. Users should verify this rate before proceeding.</li>
                        </ul>
                        <h3>4. Account Suspension and Termination</h3>
                        <p>Your account may be suspended or terminated if:</p>
                        <ul>
                          <li>You provide false or misleading information.</li>
                          <li>Suspicious activity is detected in your account.</li>
                          <li>Your actions violate these Terms of Service, applicable laws, or QuickChain’s compliance policies.</li>
                          <li>You fail to respond to inquiries or provide requested information within a reasonable timeframe.</li>
                        </ul>
                        <h3>5. Data Privacy and Security</h3>
                        <ul>
                          <li>QuickChain employs robust security measures to safeguard your data.</li>
                          <li>All sensitive data is encrypted during transmission and storage.</li>
                          <li>Access to user data is restricted to authorized personnel only.</li>
                          <li>For more information, please refer to our Privacy Policy.</li>
                        </ul>
                        <h3>6. Payment Details</h3>
                        <ul>
                          <li>All payments must be processed through authorized QuickChain channels.</li>
                          <li>Users must retain proof of payment for at least 180 days post-transaction.</li>
                        </ul>
                        <h3>7. Investigations</h3>
                        <ul>
                          <li>QuickChain reserves the right to investigate suspected violations of these Terms of Service.</li>
                          <li>User accounts under investigation may be suspended, and related materials may be removed from QuickChain systems without notice.</li>
                        </ul>
                        <h3>8. Liability and Disclaimers</h3>
                        <ul>
                          <li>QuickChain does not guarantee uninterrupted service. Users acknowledge that outages or delays may occur due to technical issues or maintenance.</li>
                          <li>QuickChain is not responsible for losses arising from incorrect wallet addresses, transaction amounts, or delays caused by external networks.</li>
                        </ul>
                        <h3>9. Prohibited Activities</h3>
                        <p>The following activities are prohibited on QuickChain:</p>
                        <ul>
                          <li>Gathering or stealing user information without authorization.</li>
                          <li>Reverse engineering or tampering with QuickChain’s platform.</li>
                          <li>Using false identities or credentials to engage with QuickChain services.</li>
                        </ul>
                        <h3>10. Grievance Policy</h3>
                        <ul>
                          <li>QuickChain is committed to resolving user complaints promptly. Feedback and complaints can be submitted via email to our customer support team.</li>
                          <li>Unresolved disputes may be escalated to our dedicated dispute resolution mechanism.</li>
                        </ul>
                        <h3>11. Updates to Terms</h3>
                        <p>QuickChain may update these Terms of Service periodically to reflect changes in policies, regulations, or services. Updates will be communicated through the QuickChain platform.
                        Thank you for choosing QuickChain. Your trust and satisfaction are our top priorities!
                        </p>
                    </div>
                </div>
             </div>
          </div>
     </div>
     <FrontFooter/>
    </>
  )
}
