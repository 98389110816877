import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./Login.scss";
import ThemeButton from "../ThemeButton/ThemeButton";
import LoginSignService from "../../Services/LoginSignService/LoginSignService";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Login() {

  let [captcha,setCaptch] = useState("");
  let [authkey,setAuthkey] = useState("");
  let [email,setEmail] = useState("");
  let [password,setPassword] = useState("");
  let [captchaValue,setCaptchaValue] = useState("");
  let [captchaId,setCaptchaId] = useState("");
  let [loading,setLoading] = useState(false);
  let navigate = useNavigate()
  let [showPassword, setShowPassword] = useState(false);
 


  const generateCaptch = async () => {
    const apicall = await LoginSignService.GenerateCaptch();
    if (apicall.state === true) {
       setCaptch(apicall.result.data.data.captchaimage) 
       setCaptchaId(apicall.result.data.data.captchakeyid) 
    } else {
      console.log('captcha api failed');
    }
  }; 
  
  const authKey = async () => {
    const apicall = await LoginSignService.authKey();
    if (apicall.state === true) {
       setAuthkey(apicall.result.data.data.apikey)
    } else {
      console.log('captcha api failed');
    }
  };
          
  useEffect(()=>{
    generateCaptch()
    authKey()
  },[])

  const retyrCaptcha = ()=>{
    generateCaptch();
    
  }

  // login api hit
  const loginHandle = async  (e)=>{
    e.preventDefault();
     
      if(validate()){
      setLoading(true)
      let formData = {email:email,password:password,api_key:authkey,captchaKeyId:captchaId,captchaValue:captchaValue,device_id:null}
        try {
          const response = await LoginSignService.loginService(formData)
          localStorage.setItem("user", JSON.stringify(response.data));
          localStorage.setItem("profileimage", response.data.data.userDetails.profileimagepath);

          setLoading(false)
          navigate("/user/dashboard");
          
      } catch (error) {
        if (error.response) {
          // The server responded with a status code outside the range of 2xx
          setLoading(false)
          console.error('Error response:', error.response.data.message);
          generateCaptch();
          toast.error(error.response.data.message)
        } else if (error.request) {
          // The request was made but no response was received
          console.error('Error request:', error.request);
          setLoading(false)
        } else {
          // Something happened in setting up the request that triggered an error
          console.error('Error message:', error.message);
          setLoading(false)
        }
      }
    }
  }

  // Validation login form
  const validate = ()=>{
    let result = true;
    if(!email)
    {
      toast.error('Email is required')
     return result = false;
    }
    if(!password)
    {
      toast.error('Password is required')
      return result = false;
    }
    if(!captchaValue)
    {
      toast.error('Captcha is required')
      return result = false;
    }
    return result;
  }

  // show hide password 
  const handlePassword = () =>{
    setShowPassword(prev => !prev);
  }
  return (
    <>
      <div className="login-box overflow-x-hidden">
        <div className="container p-0">
          <div className="row align-items-center justify-content-center g-5">
            <div className="col-sm-5 col-md-6 col-lg-4 d-sm-none">
              <div className="login-left">
                <img className="" src="/images/main-bg.svg" alt="" />
              </div>
            </div>
            <div className="col-sm-11 col-md-10 col-lg-8 col-xl-5">
              <div className="login-right">
              <div className="logo-img d-none d-sm-block">
                  <Link to="/"><img src="/images/signup-main-bg.svg" alt=""/></Link>
                </div>
                <div className="d-lg-flex d-grid gap-2 mb-3 mb-lg-0 justify-content-between align-items-center">
                  <div><h1 className="mb-1">Login</h1></div>
                  <div className="back-home"><Link className="" to="/"><i class="fa-solid fa-house"/> Back to Home</Link></div>
                </div>
                {/* <p>
                  Please provide your registered mobile number & verify OTP for
                  login
                </p> */}
                <div className="login-screen">
                  <form onSubmit={loginHandle}>
                    <label>Email</label>
                    <div className="input-group">
                      <span className="input-group-text">
                        <img className="" src="/images/mail.svg" alt="" />
                      </span>
                      <input
                        type="text"
                        aria-label="First name"
                        className="form-control"
                        value="ID"
                       
                      />
                      <span className="border-span"></span>
                      <input
                        type="email"
                        aria-label="Last name"
                        className="form-control big-input"
                        placeholder="Example@gmail.com"
                        value={email}
                        onChange={(e)=>setEmail(e.target.value)}
                      />
                    </div>
                    <div className="position-relative">
                    <label for="inputPassword5" className="form-label mt-3">
                      Password
                    </label>
                    <input
                      type={showPassword?'text':'password'}
                      id="inputPassword5"
                      className="form-control"
                      aria-describedby="passwordHelpBlock"
                      placeholder="******"
                      value={password}
                      onChange={(e)=>setPassword(e.target.value)}
                    />
                      <i class="fa-solid fa-eye d-none"></i>
                      <i class={`fa-solid ${showPassword?'fa-eye':'fa-eye-slash'}`} onClick={handlePassword}></i>
                    </div>
                    <label for="Captcha" className="form-label mt-3">
                      Captcha
                    </label>
                    <div className="d-flex align-items-center gap-2 gap-sm-3 justify-content-between">
                      <div className="d-flex align-items-center gap-2">
                        <div className="w-100">
                          {/* <input
                            type="text"
                            className="form-control"
                            id="Captcha"
                            placeholder="D75Rgft"
                          /> */}
                          <img src={captcha} className="captcha_with"/>
                        </div>
                        <div>
                          <i class="fa-solid fa-arrows-rotate" onClick={retyrCaptcha}></i>
                        </div>
                      </div>
                      <div className="w-100 mt-sm-0">
                        <input
                          type="text"
                          className="form-control"
                          id="exampleFormControlInput1"
                          placeholder="Enter captch"
                          value={captchaValue}
                          onChange={(e)=>setCaptchaValue(e.target.value)}
                        />
                      </div>
                    </div>
                    <p className="signup-txt text-end mt-3">
                        <Link to="/forgot-password">Forgot Password</Link>
                      </p>
                    
                    <ThemeButton btnName="Submit" loading={loading}/>
                      
                    
                  </form>
                  <p className="signup-txt mt-3">
                    Don’t have an account? <Link to="/sign-up">Sign up</Link>
                  </p>
                  <ToastContainer />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
