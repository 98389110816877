import React,{useEffect} from 'react';
import { Veriff } from '@veriff/js-sdk';

const Kycweb = (props) => {
  console.log(props.userid)
    useEffect(() => {
       
        const veriff = Veriff({
          apiKey: 'ba1bf7d0-61e7-4e9a-81cc-6685aeb24099',
          parentId: 'veriff-root',
          onSession: function(err, response) {      
            // redirect
            window.location.href = response.verification.url;
          }
        });
        veriff.setParams({
          vendorData: String(props.userid) // Also empty to hide vendorData input
        });
        veriff.mount();
    }, []);
     
    return (
        <>
              <div id='veriff-root'></div>
        </>
    );
}

export default Kycweb;
