// src/Home.js
import React, { useEffect, useState } from 'react';
import Dashboard from '../Dashboard/Dashboard';
import './Home.scss'
import Footer from '../Footer/Footer';
import { Link, useNavigate } from "react-router-dom";
import LoginSignService from '../../Services/LoginSignService/LoginSignService';
import WalletInfo from "../WalletInfo/WalletInfo";
import CryproHistory from "../CryptoHistory/CryptoHistory";
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import BlankCard from '../BlankCard/BlankCard';
import { DNA } from 'react-loader-spinner'
import { LineWave } from 'react-loader-spinner'
import Joyride from 'react-joyride';
import Adv from '../Modals/AppAdv/Adv';


const Home = () => {

  const auth = JSON.parse(localStorage.getItem('user'));
  const [wallet,setGetWallet] = useState("");
  const [cashwallet,setCashWallet] = useState("");
  const [referralwallet,setReferralWallet] = useState("");
  const [loaderBalance,setLoaderBalance] = useState(false)
  const [loader,setLoader] = useState(false);
  const [animationClass, setAnimationClass] = useState("");
  const [walletCurrency,setWalletCurrency] = useState([]);
  const [list,setList] = useState([]);
  const [bannerList,setBannerList] = useState([]);
  
  const [value, setValue] = useState();
  const [symbol, setSymbol] = useState();
  const [Joyrideshow,setJoyrideShow] = useState(localStorage.getItem('joyride')?localStorage.getItem('joyride'):false);
  useEffect(()=>{
    getBal();
    getWalletCurrency();
    getCryptoTran()
    // getMenuList();
    banner();
  },[])

  // Get Balance 
  const getBal = async ()=>{    
    try {
      setLoaderBalance(true)
      const data = ""
      const token = (auth.data.item.access_token); 
      const response = await LoginSignService.getBalance(data,token)
       setGetWallet(response.data.data[0].walletBalance);
       setCashWallet(response.data.data[1].walletBalance);
       setReferralWallet(response.data.data[2].walletBalance);
       
       setLoaderBalance(false)
    } catch (error) {
      if (error.response) {
        console.error('Error response:', error.response);
        setLoaderBalance(false)

      } else if (error.request) { 
        console.error('Error request:', error.request);
        setLoaderBalance(false)

      } else {
        console.error('Error message:', error.message);
      }
    }
  }

  //get Menu List
  // const getMenuList = async ()=>{
  //   try {
  //     setLoader(true)
  //     const data = "";
  //     const token = (auth.data.item.access_token); 
  //     const response = await LoginSignService.getMenu(token)
  //     localStorage.setItem('sidemenu', JSON.stringify(response.data.data));
  //     setLoader(false)

  //   } catch (error) {
  //     if (error.response) {
  //       console.log(error.data.data.message)
  //       setLoader(false)

  //     } else if (error.request) { 
  //       console.error('Error request:', error.request);
  //       setLoader(false)

  //     } else {
  //       console.error('Error message:', error.message);
  //     }
  //   }
  // }

  // Get Wallet Currency  
  const getWalletCurrency = async ()=>{    
    try {
      const token = (auth.data.item.access_token); 
      const response = await LoginSignService.getWalletCurrency(token)
       setWalletCurrency(response.data.data);
    } catch (error) {
      if (error.response) {
        console.error('Error response:', error.response);
      } else if (error.request) { 
        console.error('Error request:', error.request);
      } else {
        console.error('Error message:', error.message);
      }
    }
  }

  const handleCurrencyChange = (event) => {
    // Trigger the currency rate update function
    getCurrencyRate(event);
 
    // Update the animation class to flipanimate
    setAnimationClass("flipanimate");
 
    // Reset to outflipanimate after animation
    setTimeout(() => setAnimationClass(""), 500); // Adjust the timeout to match your animation duration
  };
  // Select Currency
  const getCurrencyRate = (e)=>{
    setValue(e.target.value)
    setSymbol(e.target[e.target.selectedIndex].getAttribute("data-symbol"))

  }

  //Get Crypto Transaction
  const getCryptoTran = async()=>{
    try {
      setLoader(true);
      const data = {};
      const token = (auth.data.item.access_token); 
      const response = await LoginSignService.getCryptoTransaction(data,token)
      setList(response.data.data)
      setLoader(false);

    } catch (error) {
      if (error.response) {
        console.log(error.data.data.message)
        setLoader(false);

      } else if (error.request) { 
        console.error('Error request:', error.request);
        setLoader(false);

      } else {
        console.error('Error message:', error.message);
      }
    }
  }


  function getButtonColor(status) {
    switch (status.toUpperCase()) {
      case "PENDING":
        return "text-warning";
      case "DECLINED":
        return "text-danger";
      case "APPROVED":
        return "";
      case "PROCESSING":
        return "text-info";
      case "COMPLETED":
        return "button-completed";
      default:
        return "";
    }
  }
  
  const [run, setRun] = useState(false); // Controls whether the tour is running
  const [stepIndex, setStepIndex] = useState(0);

  // Define tour steps joride
  const steps = [
    {
      target: "#notifications",
      content: "Click the bell to see notifications",
    },
    {
      target: "#sell-guide",
      content: "Click to sell your crypto",
    },
    {
      target: "#wallet-guide",
      content: "Click wallet to withdraw money",
    },
  ];

  useEffect(() => {
    // Check if the tour has been shown before
    const tourShown = localStorage.getItem("tourShown");
    if (!tourShown) {
      setRun(true); // Start the tour
      localStorage.setItem("tourShown", "true"); // Mark the tour as shown
    }
  }, []);

  const handleJoyrideCallback = (data) => {
    const { action, type, status, index } = data;

    if (type === "step:after") {
      setStepIndex(index + 1); // Advance to the next step
    }

    if (status === "finished" || status === "skipped") {
      setRun(false); // End the tour
      setStepIndex(0); // Reset the step index for potential future runs
    }
  };
  const [showTooltip, setShowTooltip] = useState(true); // Show tooltip by default

  useEffect(() => {
    // Hide tooltip after 10 seconds
    const timer = setTimeout(() => {
      setShowTooltip(false);
    }, 10000); // 10000 milliseconds = 10 seconds

    // Cleanup the timer if the component is unmounted before the delay
    return () => clearTimeout(timer);
  }, []);
 
  const banner =async ()=>{
    try {
      const token = (auth.data.item.access_token); 
      const response = await LoginSignService.getBanner(token)
      setBannerList(response.data.data)
    } catch (error) {
      if (error.response) {
        console.log(error.data.data.message)
      } else if (error.request) { 
        console.error('Error request:', error.request);
      } else {
        console.error('Error message:', error.message);
      }
    }
  }

  return (
    <Dashboard>
      {
        Joyrideshow?(<>
        <Joyride
        steps={steps}
        run={run} // Start the tour
        stepIndex={stepIndex} // Controls the current step
        continuous={true} // Automatically move to the next step
        scrollToFirstStep={true}
        showSkipButton={true}
        callback={handleJoyrideCallback}
        locale={{
          back: "",
          next: "Next",
          skip: "Skip",
          last: "Finish",
        }}
        styles={{
          options: {
            zIndex: 10000,
          },
          buttonNext: {
            backgroundColor: "#a80f56", // Green background for Next button
            color: "#000", // White text color
            borderRadius: "4px", // Optional: Rounded corners
            padding: "10px 30px",
            color: "#fff", // Optional: Padding
          },
          buttonSkip: {// Green background for Next button
            color: "#fff", // White text color // Optional: Padding
          },
          buttonClose: {
            display: "none", // Hides the cross button
          },
        }}
      />
        </>):(<></>)
      }
      
      <div className='main-content home ml-250'>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-sm-12 col-md-12 col-lg-9">
                <div className="profile-info-card pb-0">
                  <div className="profile-completion d-flex align-items-end justify-content-between">
                    <div className="d-flex align-items-center gap-3">
                      <div>
                        <h1 className='fs-3'>Dashboard</h1>
                        <span class="fs-6 fw-lighter">Take a overview of your recent activities</span>
                      </div>
                    </div>
                  </div>
                  <div className="wallet-balance position-relative z-2">
                    <div className="wb-overlay"></div>
                    <div className="row">
                      <div className="col-6">
                        <div className="d-grid gap-4">
                        <div>
        <h5>Wallet Balance</h5>
        <h4 className={animationClass}>
          {loaderBalance ? (
            <>
              <LineWave
                visible={true}
                height="50"
                width="50"
                color="#4fa94d"
                ariaLabel="line-wave-loading"
                wrapperStyle={{}}
                wrapperClass=""
                firstLineColor=""
                middleLineColor=""
                lastLineColor=""
              />
            </>
          ) : (
            <>
              <span>
                {symbol ? symbol : "₦ "}
                &nbsp;
                {value ? (wallet / value).toFixed(2) : wallet}
              </span>
            </>
          )}
        </h4>
      </div>
      <div>
        <h5>Select Currency</h5>
        {loaderBalance ? (
          <>
            <LineWave
              visible={true}
              height="50"
              width="50"
              color="#4fa94d"
              ariaLabel="line-wave-loading"
              wrapperStyle={{}}
              wrapperClass=""
              firstLineColor=""
              middleLineColor=""
              lastLineColor=""
            />
          </>
        ) : (
          <>
            <select
              className="form-select"
              aria-label="Default select example"
              onChange={handleCurrencyChange}
            >
              {walletCurrency.map((item, index) => (
                <option
                  key={index}
                  data-symbol={item.symbol}
                  value={item.exchangeRate}
                >
                  {item.currencyName}
                </option>
              ))}
            </select>
          </>
        )}
      </div>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="d-grid gap-4">
                          <div>
                            <h5>Cashback Wallet</h5>
                            <h4>
                            {loaderBalance?(<>
                                <LineWave
                                  visible={true}
                                  height="50"
                                  width="50"
                                  color="#4fa94d"
                                  ariaLabel="line-wave-loading"
                                  wrapperStyle={{}}
                                  wrapperClass=""
                                  firstLineColor=""
                                  middleLineColor=""
                                  lastLineColor=""
                                />
                              </>):
                              (<>
                                ₦ {cashwallet??''}
                              </>)}
                            </h4>
                          </div>
                          <div>
                            <h5>Referral Wallet</h5>
                            <h4>{loaderBalance?(<>
                                <LineWave
                                  visible={true}
                                  height="50"
                                  width="50"
                                  color="#4fa94d"
                                  ariaLabel="line-wave-loading"
                                  wrapperStyle={{}}
                                  wrapperClass=""
                                  firstLineColor=""
                                  middleLineColor=""
                                  lastLineColor=""
                                />
                              </>):
                              (<>
                                ₦ {referralwallet??''}
                              </>)}</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="recent-activity">
              <div className="row justify-content-center">
                <div className="col-sm-12 col-md-12 col-lg-9">
                  <div className="d-flex justify-content-between align-items-center">
                    <h3>Recent Activity</h3>
                    {['left'].map((placement) => (
        <OverlayTrigger
          key={placement}
          placement={placement}
          show={showTooltip}
          overlay={
            <Tooltip className='download-app' id={`tooltip-${placement}`}>
              <Link to="#"><img src='/images/download-app.png'/></Link>
            </Tooltip>
          }
        >
          <Button style={{background: 'transparent', border: 'none', padding: '0px'}}><img src="/images/union.svg" alt="union" /></Button>
        </OverlayTrigger>
      ))}
                  </div>
                  <div className="four-recent d-flex justify-content-between align-items-center">
                    <div className="crad-recent">
                      <Link to="/user/transcation">
                      <button>
                      <img className='trans-active' src="/images/convert.svg" alt="" />
                      <h5 className='text-white'>Transactions</h5>
                      </button>
                      </Link>
                    </div>
                    <div className="crad-recent" id='sell-guide'>
                      <Link to="/user/cryptoquicksell">
                        <button>
                        <img src="/images/sell-arrow.svg" alt="" />
                        <h5>Sell</h5>
                        </button>
                      </Link>
                    </div>
                    <div className="crad-recent" id='wallet-guide'>
                      <Link to="/user/wallet">
                      <button>
                      <img src="/images/wallet-icon.svg" alt="" />
                      <h5>Wallet</h5>
                      </button>
                      </Link>
                    </div>
                    <div className="crad-recent">
                      <Link to="/user/account">
                        <button>
                        <img src="/images/account.svg" alt="" />
                        <h5>Account</h5>
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bitcoin-box">
              <div className="row justify-content-center">
                <div className="col-sm-12 col-md-12 col-lg-9">
                  {
                    loader?(<>
                     <div className="row">
                          <div className="col-md-12 text-center">
                          <DNA
                              className="text-center"
                              visible={true}
                              height="80"
                              width="80"
                              ariaLabel="dna-loading"
                              wrapperStyle={{}}
                              wrapperClass="dna-wrapper"
                            />
                          </div>
                        </div>  
                    </>):(<>
                      {
                        list.length>0?(<>
                        {
                          list.map((item)=>(
                            <div className="inner-bitcoin d-flex justify-content-between align-items-center">
                              <div className="d-flex align-items-start gap-3">
                                <div className="bitcoin-icon">
                                  <img src={item.crypto_icon} alt="" width="40px" />
                                </div>
                                <div className="text-bitcoin">
                                  <div className="d-flex gap-2 align-items-center">
                                    <h4>{(parseFloat(item.received_crypto_amount) || 0).toFixed(4)} {item.crypto_currency}</h4>
                                    <button>
                                      {["bottom"].map((placement, idx) => (
                                        <CryproHistory
                                          key={idx}
                                          placement={placement}
                                          name={placement}
                                          crypto_currency={item.crypto_currency}
                                          transactionID={item.transaction_id}
                                          network={item.network}
                                          date={item.date}
                                          time={item.time}
                                          status={item.status}
                                          received={item.received_crypto_amount}
                                          nairavalue={`₦ ${item.Naira_credited_amount}`}
                                          usdvalue={item.usd_exchange}
                                          trandingrate={item.nairaRate}
                                        />
                                      ))}
                                    </button>
                                  </div>
                                  <h5 className="fs-6 mb-2">Deposit</h5>

                                  <h6>{item.status}</h6>
                                </div>
                              </div>
                              <div className="bitcoin-amount">
                                  <p>{`₦ ${item.Naira_credited_amount}`}</p>
                                  <span>{item.date}</span>
                              </div>
                            </div>
                          ))
                        }
                        </>):(<>
                        <BlankCard/>
                        </>)
                      }
                    </>)
                  }
                  
                 
                </div>
              </div>
            </div>
            <Footer/>
            {
              bannerList.length>0?(<>
              <Adv bannerList={bannerList}/>
              </>):(<></>)
            }
            
          </div>
      </div>
    </Dashboard>
  );
};

export default Home;