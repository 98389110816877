import React, { useState } from "react";
import FrontHeader from "../FrontPage/frontheader";
import FrontFooter from "../FrontPage/frontfooter";
import "./Contact.scss";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import ThemeButton from "../ThemeButton/ThemeButton";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoginSignService from "../../Services/LoginSignService/LoginSignService";
import Success from "../Modals/Success/Success";

export default function Contact() {

  const[name,setName] = useState(null);
  const[email,setEmail] = useState(null);
  const[subject,setSubject] = useState(null);
  const[message,setMessage] = useState(null);
  const [loading,setLoading] = useState(false);
  const [success,setSuccess] = useState(false);
  
  //Contact Save
  const contactSave = async (e)=>{
    e.preventDefault();
    if(validate())
    {
      setLoading(true)
      let data = {name:name,email:email,subject:subject,message:message}
      try {
        const response = await LoginSignService.contactus(data)
        console.log(response.data)
        setLoading(false)
        setName("");
        setEmail("");
        setSubject("");
        setMessage("");
        setSuccess(true)
      } catch (error) {
        if (error.response) {
          console.log(error.response.data)
          toast.error(error.response.data.message)
          setLoading(false)

        } else if (error.request) { 
          console.error('Error request:', error.request);
        } else {
          console.error('Error message:', error.message);
        }
      }
    }
  }

  // Validation 
  const validate =()=>{
    let result = true;
    if(!name)
    {
      toast.error("Name is required")
      return result = false;
    }
    if(!email)
    {
      toast.error("Email is required")
      return result = false;
    }
    if(!subject)
    {
      toast.error("Subject is required")
      return result = false;
    }
    if(!message)
    {
      toast.error("Message is required")
      return result = false;
    }
    return result;
  }

  const openInNewTab = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };
  const openInNewTab1 = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <>
      <FrontHeader />
      <div className="contact-text mb-5">
        <div className="container">
          <div className="d-lg-flex justify-content-between align-items-center">
            <h1 className="text-left fs-1 text-white mb-3 mb-lg-0">Contact Us</h1>
            <div>
              <nav aria-label="breadcrumb mb-0">
                <ol class="breadcrumb mb-0 justify-content-lg-end" style={{paddingRight: '15px'}}>
                  <li class="breadcrumb-item">
                    <Link className="text-white" to="/">
                      Home
                    </Link>
                  </li>
                  <li
                    class="breadcrumb-item active text-white"
                    aria-current="page"
                  >
                    Contact
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className="contact-box my-4">
        <div className="container">
          <div className="row gy-5">
            <div className="col-lg-8 order-2 order-lg-1">
              <div className="contact-card">
                <div className="d-flex gap-1 align-items-start mb-4">
                  <img
                    src="/images/hand.gif"
                    alt=""
                    style={{ width: "60px", marginTop: "-5px" }}
                  />
                  <div>
                    <h2>Say Hello</h2>
                    <p>Have something to say to us?</p>
                  </div>
                </div>
                <div className="contact-form-box">
                  {
                          success?(<>
                           <Success setSuccessShow={""} setShow={""} setSuccess={setSuccess}
                          img="tick.gif"
                          success={`Success`}
                          message1={`Your query is successfully submitted. We will get back to you as soon as possible.`}/>
                            </>):""
                          }
                  <Form onSubmit={contactSave}>
                    <div className="row">
                      <div className="col-lg-6">
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Control type="text" placeholder="Name" value={name} onChange={(e)=>setName(e.target.value)}/>
                        </Form.Group>
                      </div>
                      <div className="col-lg-6">
                        <Form.Group
                          className="mb-4"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Control type="text" placeholder="Email" value={email} onChange={(e)=>setEmail(e.target.value)} />
                        </Form.Group>
                      </div>
                    </div>
                    <Form.Group
                      className="mb-4"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Control type="text" placeholder="Subject" value={subject} onChange={(e)=>setSubject(e.target.value)}/>
                    </Form.Group>
                    <Form.Group
                      className="mb-4"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Control
                        type="textarea"
                        placeholder="Message"
                        style={{ height: "150px" }}
                        value={message}
                        onChange={(e)=>setMessage(e.target.value)}
                      />
                    </Form.Group>
                    <div className="row">
                      <div className="col-lg-4">
                        <ThemeButton btnName="Send"  loading={loading}/>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
            <div className="col-lg-4 order-1 order-lg-2">
              <div className="contact-card">
                <div className="d-flex gap-1 align-items-start">
                  <img
                    src="/images/house.png"
                    alt=""
                    style={{ width: "30px" }}
                  />
                  <div>
                    <p className="mb-0 fs-6">Pay us a visit</p>
                    <span className="fs-5 d-block">Lekki, Lagos, Nigeria</span>
                  </div>
                </div>
                <div className="d-flex gap-1 align-items-start mt-4">
                  <img
                    src="/images/email.png"
                    alt=""
                    style={{ width: "30px" }}
                  />
                  <div>
                    <p className="mb-0 fs-6">Send us your query anytime!</p>
                    <span className="fs-5 d-block">
                      <a
                        className="text-white"
                        href="mailto:support@quickchain.com"
                      >
                        support@quickchain.com
                      </a>
                    </span>
                  </div>
                </div>
                <div className="contact-social-media">
                  <h2>Follow Us On</h2>
                  <ul className="d-flex gap-4">
                    {/* <li>
                      <Link>
                        <i class="fa-brands fa-facebook-f" />
                      </Link>
                    </li> */}
                    <li onClick={() => openInNewTab('https://twitter.com/quickchainapp')}>
                      <Link>
                        <i class="fa-brands fa-x-twitter" />
                      </Link>
                    </li>
                    <li onClick={() => openInNewTab1('https://www.instagram.com/quickchainapp')}>
                      <Link>
                        <i class="fa-brands fa-instagram" />
                      </Link>
                    </li>
                    {/* <li>
                      <Link>
                        <i class="fa-brands fa-linkedin-in" />
                      </Link>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FrontFooter />
      <ToastContainer/>
    </>
  );
}
