import React, { useEffect, useState } from "react";
import Dashboard from "../Dashboard/Dashboard";
import Footer from "../Footer/Footer";
import TransactionCard from "../TransactionCard/TransactionCard";
import "./ReferralWallet.scss";
import RequestWithdrawal from "../Modals/RequestWithdrawal/RequestWithdrawal";
import LoginSignService from '../../Services/LoginSignService/LoginSignService';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DNA } from 'react-loader-spinner'
import { ColorRing } from 'react-loader-spinner'
import BlankCard from "../BlankCard/BlankCard";
import { CopyToClipboard } from 'react-copy-to-clipboard';


export default function ReferralWallet() {

  const auth = JSON.parse(localStorage.getItem("user"));
 
  const [walletHistory,setWalletHistory] = useState("");
  const [minwithdwalLimit,setminwithdwalLimit] = useState("");
  const [maxwithdwalLimit,setmaxwithdwalLimit] = useState("");
  const [withDrawalHistory,setWithDrawalHistory] = useState([]);
  const [walletCurrency,setWalletCurrency] = useState([]);
  const [amount,setAmount] = useState("");
  const [password,setPassword] = useState("");
  const [loading,setLoading] = useState(false);
  const [successShow,setSuccessShow] = useState(false);
  const [loader,setLoader] = useState(false)
  const [loaderBalance,setLoaderBalance] = useState(false)
  const [referralcode,setReferralCode] = useState("");

  const [show1,setShow1] = useState(false);

   // Get Balance 
   const getBal = async ()=>{    
    try {
      setLoaderBalance(true)
      const token = (auth.data.item.access_token); 
      const response = await LoginSignService.getBalance(3,token)
      setWalletHistory(response.data.data.wallet_amount);
      setminwithdwalLimit(response.data.data.min_withdrawable_amount)
      if(response.data.data.kycStatus == 0)
      {
        setmaxwithdwalLimit(response.data.data.max_withdrawable_amount_without_KYC??'')
      }   
      setLoaderBalance(false)

    } catch (error) {
      if (error.response) {
        console.error('Error response:', error.response);
        setLoaderBalance(false)

      } else if (error.request) { 
        console.error('Error request:', error.request);
        setLoaderBalance(false)

      } else {
        console.error('Error message:', error.message);
      }
    }
  }

  // Get WithDrawal History 
  const getWith = async ()=>{    
    try {
      setLoader(true);
      const token = (auth.data.item.access_token); 
      const response = await LoginSignService.withDrawalHistory(3,token)
       setWithDrawalHistory(response.data.data);
       setLoader(false);
    } catch (error) {
      if (error.response) {
        console.error('Error response:', error.response);
        setLoader(false);

      } else if (error.request) { 
        console.error('Error request:', error.request);
        setLoader(false);

      } else {
        console.error('Error message:', error.message);
      }
    }
  }

  useEffect(()=>{
      getBal();
      getWith();
      getUserDetails();
  },[])

  // Handle Request
  const handleRequest = async (e) =>{
    e.preventDefault();

    let data = {amount:amount,password:password,walletTypeId:3,platform:'web'}
    if(validate())
    {
      try {
        setLoading(true);
        const token = (auth.data.item.access_token); 
        const response = await LoginSignService.withdrawBalance(data,token)
        //  setAmount("")
        //  setPassword("")
        getWith()
        getBal()
        setSuccessShow(true);
        setLoading(false);
        setShow1(false)
      } catch (error) {
        if (error.response) {
          setLoading(false);
          console.error('Error response:', error.response.data.message);
          toast.error(error.response.data.message)
        } else if (error.request) { 
          setLoading(false);
          console.error('Error request:', error.request);
        } else {
          setLoading(false);
          console.error('Error message:', error.message);
        }
      }
    }
  }

  // Validate 
  const validate =()=>{
   
    let result = true;
    if(!amount)
    {
      toast.error("Please enter amount");
      return result = false;
    }
    if(Number(amount) < Number(minwithdwalLimit))
    {
      toast.error("Withdraw amount should not be less than 1000.");
      return result = false;
    }
    // if(maxwithdwalLimit)
    // {
    //     if(Number(amount) > Number(maxwithdwalLimit))
    //     {
    //       toast.error(`Maximum amount ${maxwithdwalLimit} debit`);
    //       return result = false;
    //     }
    // }
    if(Number(amount) > Number(walletHistory))
    {
      toast.error(`Insufficient funds! You can withdraw at max ${walletHistory}`);
      return result = false;
    }
    if(!password)
    {
      toast.error("Please enter password");
      return result = false;
    }
    return result;
  }

  //get User Details
  const getUserDetails =async ()=>{
    try {
      setLoaderBalance(true)
      const token = (auth.data.item.access_token); 
      const response = await LoginSignService.getUserDetails(token)
      setReferralCode(response.data.data.referralCode)
      setLoaderBalance(false)

    } catch (error) {
      if (error.response) {
        console.log(error.data.data.message)
        setLoaderBalance(false)

      } else if (error.request) { 
        console.error('Error request:', error.request);
      } else {
        console.error('Error message:', error.message);
      }
    }
  }

  // Text Copy
  const handleCopy = () => {
    toast('Text copied to clipboard!');
  };
  return (
    <>
      <Dashboard>
        <div className="main-content referralwallet ml-250">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-sm-12 col-md-12 col-lg-9">
                <div className="profile-info-card pb-0">
                  <div className="profile-completion d-flex align-items-end justify-content-between">
                    <div className="d-flex align-items-center gap-3">
                      <div>
                        <h1 className="fs-3">Referral Balance</h1>
                        {/* <span className="fs-6 fw-lighter">
                          Balance available for withdrawal
                        </span> */}
                      </div>
                    </div>
                  </div>
                  <div className="wallet-balance position-relative z-2">
                    <div className="wb-overlay"></div>
                    <div className="row align-items-center">
                      <div className="col-lg-8">
                        <h5>
                          <i class="fa-solid fa-wallet" /> Referral Balance
                        </h5>
                        <div className="d-grid gap-4 align-items-center">
                          <div>
                            {
                              loaderBalance?(<>
                                    <ColorRing
                                      visible={true}
                                      height="80"
                                      width="80"
                                      ariaLabel="color-ring-loading"
                                      wrapperStyle={{}}
                                      wrapperClass="color-ring-wrapper"
                                      colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                                    />
                              </>):(<>
                                <h4 className="fs-3 fs-lg-1">₦ {walletHistory?walletHistory:0}</h4>
                                {
                                  Number(walletHistory) >= Number(minwithdwalLimit) ?(<>
                                  </>):(<>
                                    <p className='low-balance-alart text-danger mt-2 mb-0'><i className="fa-solid fa-circle-info"/> Your balance is too low to initiate a withdrawal process</p>
                                  </>)
                                }
                              </>)
                            }
                            
                          </div>
                        </div>
                        <div></div>
                      </div>
                      <div className="col-lg-4 mt-3 mt-lg-0">
                        <div className="">
                        {
                           Number(walletHistory) >= Number(minwithdwalLimit) ?(<>
                             <RequestWithdrawal handleRequest={handleRequest} setAmount={setAmount} setPassword={setPassword} amount={amount} password={password} loading={loading} setSuccessShow={setSuccessShow} successShow={successShow} setShow1={setShow1} show1={show1}/>
                           </>):(<>
                            <button className="btn-low-balance btn w-100 text-danger bs-danger-bg-subtle">
                            <span><i class="fa-solid fa-money-bill-wave"/> Balance Low</span>
                           </button>
                           </>)

                          }
                          
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="recent-activity">
              <div className="row justify-content-center">
                <div className="col-sm-12 col-md-12 col-lg-5">
                <div className="coupon-box mb-4">
                    <div class="coupon">
                      <div class="left">
                      <div >Referral Code</div>
                      </div>
                      <div class="center">
                        <div className="d-felx align-item-center">
                          <h2 className="mb-0">
                            {
                              loaderBalance?(<>
                              <ColorRing
                                      visible={true}
                                      height="80"
                                      width="80"
                                      ariaLabel="color-ring-loading"
                                      wrapperStyle={{}}
                                      wrapperClass="color-ring-wrapper"
                                      colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                                    />
                              </>):(<>{referralcode}</>)
                            }
                            </h2>
                            <CopyToClipboard text={referralcode??''} onCopy={handleCopy}>
                            <span><i class="fa-regular fa-copy fs-3"></i></span>
                            </CopyToClipboard>
                          
                        </div>
                      </div>

                      <div class="right">
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-9">
                  <div className="d-flex justify-content-between align-items-center">
                    <h3>Recent Activity</h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="bitcoin-box">
              <div className="row justify-content-center">
                <div className="col-sm-12 col-md-12 col-lg-9">
                  {
                    loader?(<>
                     <div className="row">
                          <div className="col-md-12 text-center">
                          <DNA
                              className="text-center"
                              visible={true}
                              height="80"
                              width="80"
                              ariaLabel="dna-loading"
                              wrapperStyle={{}}
                              wrapperClass="dna-wrapper"
                            />
                          </div>
                        </div>
                    </>):(<>
                    {
                      withDrawalHistory.length>0?(<>
                      {
                        withDrawalHistory.map((item)=>(
                          <TransactionCard name={item.transactionType} tag={item.status} amount={`₦ ${item.amount}`} date={`${item.date}`} time={`${item.time}`}
                          bankname={item.bankName}
                          accountname={item.accountName}
                          accountnumber={item.accountNumber}
                          />
                        ))
                      }
                      </>):(<>
                      <BlankCard />
                      </>)
                    }
                      
                    </>)
                  }
                  
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </Dashboard>
      <ToastContainer />
    </>
  );
}
